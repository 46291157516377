/**
 *
 * Definitions
 *
 * eventNamel: eventName || 'Link Clicked'
 *
 * interactionElement: something unique to this particular event
 *
 * interactionSection: for example "Homepage Hero" (as defined in Dato). Allows grouping events by a particular unique section.
 *
 * interactionCategory: this allows to group events by type of section useful analysing overall effectiveness of all instances of this component across the site. This is intentionally hardcoded.
 *
 * position: this allows us to analyse how the position of an element affects it's overall effectiveness
 *
 *
 */
export default ra => ({
  async linkClicked (params, eventName = 'Link Clicked') {
    await ra.track(eventName, {
      linkText: params.linkText,
      position: params.position,
      interactionElement: params.interactionElement,
      interactionSection: params.interactionSection,
      interactionCategory: params.interactionCategory,
    })
  },

  async globalBannerClicked (params) {
    await ra.track('Global Banner Clicked', params)
  }
})
