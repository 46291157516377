import { productAttributes } from '~/assets/js/constants/productAttributes'
import { setAuthentication } from '~/assets/js/helpers/commerce'

export default ({ client, state }) => ({
  splitIntoBatches(input, chunkSize = 75) {
    return [...Array(Math.ceil(input.length / chunkSize))].map((_) =>
      input.splice(0, chunkSize)
    )
  },

  async getBatch(SKUs) {
    try {
      const baseUrl = `/skus?filter[q][code_in]=${SKUs.join(
        ','
      )}&include=prices,stock_items.stock_location`
      const firstPageRes = await client.get(
        `${baseUrl}&page[number]=1&page[size]=25`
      )
      const firstPageProducts = firstPageRes.get(productAttributes)

      if (firstPageRes.dataset.meta.page_count > 1) {
        const requests = [
          ...Array(firstPageRes.dataset.meta.page_count).keys(),
        ].map((_, i) => {
          const pageNumber = i + 1
          return client.get(
            `${baseUrl}&page[number]=${pageNumber}&page[size]=25`
          )
        })

        const res = await Promise.all(requests)
        const restProducts = res.map((r) => r.get(productAttributes))

        return [...firstPageProducts, ...restProducts.flat()]
      }

      return firstPageProducts
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async get(SKUs) {
    const knownSKUs = SKUs.filter((sku) => sku in state.fetchedProducts)
    const knownResponses = knownSKUs.map((sku) => state.fetchedProducts[sku])

    const unknownSKUs = [
      ...new Set(SKUs.filter((sku) => !knownSKUs.includes(sku))),
    ]

    if (!unknownSKUs.length) return knownResponses

    await setAuthentication(state)

    const responses = (
      await Promise.all(
        this.splitIntoBatches(unknownSKUs).map((batch) => this.getBatch(batch))
      )
    ).flat()

    state.fetchedProducts = {
      ...state.fetchedProducts,
      ...Object.fromEntries(
        responses.map((response) => [response.code, response])
      ),
    }

    return [...knownResponses, ...responses]
  },
})
