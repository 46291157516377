import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolveLifestyleSelectedOptions as resolveOptions,
  resolveLifestyleOptionsFromSKU as resolveOptionsFromSKU
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Fclear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Fclear_{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Fclear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Idarkgrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_MIgrey_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Iblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_MIblack_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Inavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_MInavy_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mblack_{nnn}.png'
    },
    Mblacktortoise: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mblacktortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mblacktortoise_{nnn}.png'
    },
    Mcarbon: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mcarbon_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mcarbon_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mgrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mgrey_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mnavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mnavy_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mstone_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mstone_{nnn}.png'
    },
    Mtortoise: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mtortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mtortoise_{nnn}.png'
    },
    Itortoise: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mtortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mtortoise_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Classics/c3f/c3f_Mwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3f_Mwhite_{nnn}.png'
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220520/Classics/c3i/c3i_Bblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3i_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220520/Classics/c3i/c3i_Bblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3i_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220520/Classics/c3i/c3i_Bgold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3i_Bgold_{nnn}.png'
    },
    Bgreen: {
      pathTemplate: 'gs://sg360/220520/Classics/c3i/c3i_Bgreen_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3i_Bgreen_{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/220520/Classics/c3i/c3i_Bred_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3i_Bred_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220520/Classics/c3i/c3i_Bsilver_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3i_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/220520/Classics/c3i/c3i_Bwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3i_Bwhite_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/220520/Classics/c3i/c3i_Bgunmetal_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3i_Bgunmetal_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    blue: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le/c3le_blue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3le_blue_{nnn}.png'
    },
    brown: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le/c3le_brown_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3le_brown_{nnn}.png'
    },
    fire: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le/c3le_fire_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3le_fire_{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le/c3le_gold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3le_gold_{nnn}.png'
    },
    green: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le/c3le_green_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3le_green_{nnn}.png'
    },
    pink: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le/c3le_pink_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3le_pink_{nnn}-b.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le/c3le_silverblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3le_silverblue_{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le/c3le_smoke_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Classics3/c3le_smoke_{nnn}.png'
    }
  }
}

export const reflection = {
  id: 'reflections',
  isReflection: true,
  name: 'Reflections',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    r5pc: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le Cloud Overlay/5/Classics_Cloud Overlay 5_{nnn}.png'
    },
    r10pc: {
      pathTemplate: 'gs://sg360/220520/Classics/c3le Cloud Overlay/10/Classics_Cloud Overlay 10_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220520/Classics/c3lg/c3lg_N_{nnn}.png'
    },
    eP: {
      pathTemplate: 'gs://sg360/220520/Classics/c3lg/c3lg_P_{nnn}.png'
    },
    e8: {
      pathTemplate: 'gs://sg360/220520/Classics/c3lg/c3lg_8_{nnn}.png'
    },
    e8P: {
      pathTemplate: 'gs://sg360/220520/Classics/c3lg/c3lg_8P_{nnn}.png'
    },
    none: {
      disabled: true
    }
  }
}

export default {
  layers: [
    background,
    frame,
    icons,
    lenses,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveOptionsFromSKU
}