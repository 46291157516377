import { setAuthentication } from '~/assets/js/helpers/commerce'

export default ({ client, state }) => ({
  create: (orderId) => async (attributes) => {
    await setAuthentication(state)
    const lineItem = await client.post('line_items', {
      data: {
        type: 'line_items',
        attributes,
        relationships: {
          order: {
            data: {
              type: 'orders',
              id: orderId,
            },
          },
        },
      },
    })

    return lineItem
  },

  update: (id) => async (attributes) => {
    await setAuthentication(state)
    return await client.patch(`line_items/${id}`, {
      data: {
        id,
        type: 'line_items',
        attributes,
      },
    })
  },

  delete: async (id) => {
    await await setAuthentication(state)
    await client.delete(`line_items/${id}`)
  },
})
