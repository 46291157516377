import ShippingMethod from './ShippingMethod'

export default class Shipment {
  constructor(data) {
    Object.assign(this, data)

    if (this.shippingMethod)
      this.shippingMethod = new ShippingMethod(this.shippingMethod)
    this.availableShippingMethods = (this.availableShippingMethods || []).map(
      (method) => new ShippingMethod(method)
    )
  }

  get isDigital() {
    return this.availableShippingMethods.some((m) => m.name.match(/digital/i))
  }

  get isPhysical() {
    return !this.isDigital
  }
}
