import { authentication } from '@commercelayer/js-auth'
import cookie from 'js-cookie'

export const getToken = async (state) => {
  if (tokenIsValid(state.token)) return state.token

  state.token = await authentication('client_credentials', {
    clientId: state.config.clientId,
    slug: state.config.slug,
    scope: `market:${state.config.market.id}`,
  })

  cookie.set(`cltoken_${state.config.market.id}`, JSON.stringify(state.token))
  
  return state.token
}

export const tokenIsValid = token => {
  if (!token) return false

  const now = Date.now()
  const tokenExpiry = token?.expires?.getTime()
  const tokenHasExpired = !tokenExpiry || tokenExpiry - now < 60000
  return !tokenHasExpired
}

export const setAuthentication = async (state) => {
  if (tokenIsValid(state.token)) return
  return await getToken(state)
}
