import cookie from 'js-cookie'
import productClient from './commerce/product.mjs'
import lineItemClient from './commerce/lineItem.js'
import lineItemOptionClient from './commerce/line-item-options.js'
import orderClient from './commerce/order.js'
import couponClient from './commerce/coupon.js'
import cartClient from './commerce/cart.js'
import { commerceClient } from '~/assets/js/clients/commerce.js'
import { getToken, setAuthentication } from '~/assets/js/helpers/commerce'
import { locales } from '~/i18n/constants.ts'
import { getMarket } from '~/assets/js/helpers/markets'
import { useCheckoutStore } from '~/stores/checkout'

export default defineNuxtPlugin(async (nuxtApp) => {
  const checkoutStore = useCheckoutStore()
  const route = useRoute()
  const { $i18n, $sentry } = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()

  const state = {
    initialised: false,

    config: {
      clientId: runtimeConfig.public.commerceClientId,
      slug: runtimeConfig.public.commerceSlug,
      endpoint: runtimeConfig.public.commerceEndpoint,
    },

    _cart: {
      lineItems: [],
    },

    set cart(value) {
      checkoutStore.SET_ORDER(value)
      this._cart = value
    },

    get cart() {
      return this._cart
    },

    fetchedProducts: {},
  }

  let client, product, order, coupon, cart, lineItem

  const initMarket = () => {
    state.config.market = getMarket(
      $i18n.locale.value,
      runtimeConfig.public.environmentName,
      locales
    )
    checkoutStore.SET_MARKET(state.config.market)
  }

  const init = async (enableAutoRefresh = false) => {
    state.config.market = getMarket(
      $i18n.locale.value,
      runtimeConfig.public.environmentName,
      locales
    )

    checkoutStore.SET_MARKET(state.config.market)

    client = commerceClient(state.config.endpoint, state)

    product = productClient({
      client,
      state: state,
    })

    lineItem = lineItemClient({
      client,
      state: state,
    })

    const lineItemOption = lineItemOptionClient({
      client,
      state: state,
    })

    order = orderClient({
      client,
      state: state,
    })

    cart = cartClient({
      state,
      order,
      lineItem,
      lineItemOption,
      $product: nuxtApp.$product,
      $sentry: nuxtApp.$sentry,
    })

    coupon = couponClient({
      state,
      order,
      cart,
      $track: nuxtApp.$track,
      $sentry: nuxtApp.$sentry,
    })

    state.cartName = `sungod_cart_${state.config.market.id}`

    let cartId = route?.query?.cart || cookie.get(state.cartName)
    if (route?.query?.cart) {
      cookie.set(state.cartName, route.query.cart, {
        expires: 30,
      })
    }

    let storedToken = cookie.get(`cltoken_${state.config.market.id}`)
    if (storedToken) {
      try {
        storedToken = JSON.parse(storedToken)
        storedToken.expires = new Date(storedToken.expires)
        state.token = storedToken
      } catch {
        // do nothing - we'll just get a new token
      }
    }

    state.initialised = true
    
    // This will return immediately if the token is valid
    await setAuthentication(state)

    let newCart
    if (!cartId) {
      // Don't create a cart if we don't have a cartId
      // We will create a cart on the first addToCart
      return
    }

    await getToken(state)

    try {
      let gatheredCart = newCart
      if (!gatheredCart) {
        gatheredCart = await cart.fetch(cartId, enableAutoRefresh)
      }

      // If cart has been completed, remove and create new cart
      if (!['draft', 'pending'].includes(gatheredCart.status)) {
        cookie.remove(state.cartName)

        await cart.create()
      }

      // If the cart is for a different market then redirect
      if (gatheredCart.currencyCode !== state.config.market.currency) {
        const countryCode =
          locales.find(
            (country) => country.currency === gatheredCart.currencyCode
          )?.countryCode || 'gb'
        const newLocale = 'en-' + countryCode
        const target = `/${newLocale}/checkout/cart?cart=${cartId}`
        window.location = target
      }
    } catch (error) {
      $sentry?.captureException(error)
      console.error(error)

      // If cart errors, remove and attempt to create new cart
      cookie.remove(state.cartName)

      await cart.create()
    }
  }

  const addToCart = async (
    customisedProduct,
    additionalProducts = [],
    options = {}
  ) => {
    // If we don't have a cartId, create a new cart
    if (!state.cart.id) {
      await cart.create()
    }

    // Throw an error if the product needs a product option but none are set
    if (customisedProduct?.lensChoice?.hasProductOptions) {
      if (!customisedProduct?.lensChoice?.activeProductOption) {
        throw new Error('No product option selected for product')
      }
    }

    const success = await cart.add([
      {
        sku:
          customisedProduct.product.pageMode === 'parts'
            ? customisedProduct.skuWithExtras
            : customisedProduct.sku,
        quantity: 1,
        isLimitedEdition: customisedProduct.isLimitedEdition,
        legacySKU: customisedProduct.legacySKU,
        adFeedSKU: customisedProduct.adFeedSKU,
        additionalProductSKU: customisedProduct?.additionalProductSKU,
        productOption:
          customisedProduct?.lensChoice?.activeProductOption || null,
        invitedToPreOrder: customisedProduct?.product?.invitedToPreOrder,
        metadata: options?.metadata,
      },
    ])

    // Build a list of all the additional products that need to be added
    const otherItems = []
    if (
      customisedProduct.conversionKitEnabled &&
      !customisedProduct.isLimitedEdition
    ) {
      // Add Conversion Kit if required
      otherItems.push({
        sku: customisedProduct.secondarySKU,
        quantity: 1,
        isLimitedEdition: false,
        legacySKU: customisedProduct.secondaryLegacySKU,
      })
    }

    // Add selected upsells
    if (Object.keys(customisedProduct.state.selectedUpsellChoices).length) {
      Object.values(customisedProduct.state.selectedUpsellChoices)
        .map((option) => ({
          sku: option.sku,
          quantity: 1,
          isLimitedEdition: option.isLimitedEdition,
          legacySKU: option.legacySKU,
          additionalProductSKU: option?.additionalProductSKU,
          productOption: option?.lensChoice?.activeProductOption || null,
        }))
        .forEach((option) => otherItems.push(option))
    }

    // Add the otherItems in a single call
    if (otherItems?.length) await cart.add(otherItems)

    // Add any additional products if required - we add these separately as we're allowed to let them fail
    try {
      await Promise.all(
        [customisedProduct.additionalProductSKU, ...additionalProducts]
          .filter((additionalProduct) => additionalProduct)
          .map(async (additionalProduct) => {
            // If additionalObject is a string, it's a SKU
            let payload
            if (typeof additionalProduct === 'string') {
              payload = {
                sku: additionalProduct,
                quantity: 1,
                isLimitedEdition: true,
                legacySKU: additionalProduct,
              }
            } else {
              payload = additionalProduct
            }

            await cart.add([payload], {
              showErrorMessage: false,
            })
          })
      )
    } catch (error) {
      // These are typically not mission-critical so don't block the add to cart.
      // The failure will be logged to Sentry from cart.js::add()
      console.error(error)
    }

    return success
  }

  const getProductFromSku = async (customisedProduct) => {
    // eslint-disable-next-line vue/max-len
    return client.get(
      `/skus?filter[q][code_eq]=${customisedProduct.sku}&include=stock_items.reserved_stock`
    )
  }

  initMarket()

  if (process.client) {
    init()
  }

  return {
    name: 'commerce',
    provide: {
      commerce: {
        init,
        product,
        cart,
        lineItem,
        coupon,
        order,
        addToCart,
        getProductFromSku,
        state,
      },
    },
  }
})
