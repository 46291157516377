import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import isoWeek from "dayjs/plugin/isoWeek"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)

export default class ShippingMethod {
  constructor(data) {
    Object.assign(this, data)
  }

  get cleanedName() {
    const nameParts = this.name.split('::')
    return nameParts[nameParts.length - 1].trim()
  }

  get isClickAndCollect() {
    return this.name.match(/click and collect/i)
  }

  get isSunGodPlus() {
    return this.name.match(/sungod\+/i)
  }

  get isSunGodPlusPurchase() {
    return this.name.match(/sungod\+.*purchase/i)
  }

  get isDomesticSunGodPlus() {
    return this.name.match(/sungod\+.*domestic/i)
  }

  get isSunGodPlusRedemption() {
    return this.name.match(/sungod\+.*redemption/i)
  }

  get isBeforeTimeDelivery() {
    return !!this.metadata.deliveryBeforeTime
  }

  get isSaturdayDelivery() {
    return this.name.match(/saturday/i)
  }

  get isDPD() {
    return this.name.match(/dpd/i)
  }

  get isDHL() {
    return this.name.match(/dhl/i)
  }

  get isRM24() {
    return this.name.match(/royal mail tracked 24/i)
  }

  get isSlowMethod() {
    return this.metadata?.price === 'S' || this.name.match(/tracked 48/i)
  }

  get formattedForStripe() {
    return {
      id: this.id,
      displayName: this.cleanedName,
      amount: Math.ceil(
        (this.priceAmountForShipmentFloat ?? this.priceAmountFloat ?? 0)
        * 100
      ),
    }
  }

  testIsEnabled(userUUID, otherMethods, shippingCountry) {
    // If shippingCountry isn't GB then all will be enabled
    if (shippingCountry !== 'GB') return true

    // If only RM24 or DPD is shown then this will never be disabled
    const dpdAvailable = otherMethods.some((method) => method.isDPD)
    const rm24Available = otherMethods.some((method) => method.isRM24)
    if ([dpdAvailable, rm24Available].filter(Boolean).length === 1) return true

    // If this is a saturday delivery method then it shouldn't be disabled
    if (this.isSaturdayDelivery) return true

    // Hash order ID to a range of 0-100 and show RM24 if the hash is less than the threshold
    const threshold = 50
    let hash = 0
    const str = `dpd:${userUUID}`
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i)
      hash += charCode
    }
    const showRM24ForDomesticOrder = hash % 100 < threshold

    if (dpdAvailable && this.isRM24) return showRM24ForDomesticOrder
    if (this.isDPD) return !showRM24ForDomesticOrder

    return true
  }

  testIsVisible(
    sungodPlusMembership = false,
    freeShipping = false,
    isPrescriptionOrder = false
  ) {
    // Saturday delivery is only visible on Thursdays from 3pm UK and Fridays at 3pm UK
    if (this.isSaturdayDelivery) {
      const currentDateTime = dayjs().tz('Europe/London')
      const day = currentDateTime.isoWeekday() // 1 (Monday) - 7 (Sunday)
      const hour = currentDateTime.hour()
      return (day === 4 && hour >= 15) || (day === 5 && hour < 15)
    }

    // Don't show any SG+ options if free shipping is enabled
    if (this.isSunGodPlus && freeShipping) {
      return false
    }

    // Don't show slow methods for prescription orders
    if (this.isSlowMethod && isPrescriptionOrder) {
      return false
    }

    // SunGod+ redemption is only visible to those members who have paid for this
    // service or above (domestic or international)
    if (this.isSunGodPlusRedemption) {
      return (
        sungodPlusMembership &&
        (this.isDomesticSunGodPlus || sungodPlusMembership === 'international')
      )
    }

    // SunGod+ purchase should only be visible to non-members and for international shipments
    // for those who have purchased domestic membership
    if (this.isSunGodPlusPurchase) {
      if (!sungodPlusMembership) return true
      return sungodPlusMembership === 'domestic' && !this.isDomesticSunGodPlus
    }

    return true
  }
}
