import buildPromoQuery from '~/gql/promo.gql.js'
import useDatoQuery from '~/composables/useDatoQuery'

export default defineNuxtPlugin(() => {
  const { $sentry, $commerce } = useNuxtApp()

  const promoState = {
    activePromos: [],
    activePromo: {}
  }

  async function getAllGlobalPromos() {
    const query = buildPromoQuery()
    const response = await useDatoQuery({
      query
    })

    // For some reason this query is sometimes left idle. ¯\_(ツ)_/¯
    if (response.pending.value) await response.execute()

    await getVerifiedActiveGlobalPromos(response.data.value.allGlobalPromos)
  }

  async function getVerifiedActiveGlobalPromos(allGlobalPromos) {
    const transformedGlobalPromos =
      transformGlobalPromosDatoData(allGlobalPromos)

    if (transformedGlobalPromos.length > 0) {
      const globalPromosWithClData = await fetchClPromoData(
        transformedGlobalPromos
      )

      const rawActivePromos = filterActivePromos(globalPromosWithClData)

      promoState.activePromos = buildPromosWithHurdlePrice(rawActivePromos)
      promoState.activePromo = promoState.activePromos[0] || {}
    }
  }

  function transformGlobalPromosDatoData(allGlobalPromos) {
    return allGlobalPromos.map((promo) => ({
      ...promo,
      promotionIds: promo.promotionIds?.split(',')?.map((id) => id.trim()) || [],
      activeFrom: promo.activeFrom ? new Date(promo.activeFrom) : null,
      activeUntil: promo.activeUntil ? new Date(promo.activeUntil) : null,
    }))
  }

  function buildPromosWithHurdlePrice(rawActivePromos) {
    return rawActivePromos.map((promo) => {
      let hurdlePrice = promo.clPromos.find(
        (promo) => promo.hurdle_amount
      )?.hurdle_amount

      // If the floor of hurdlePrice equals hurdlePrice - 0.01 then
      // we've been crafty to get around the fact that Commerce Layer
      // includes the price of the free item in the hurdle amount
      // so we need to subtract 0.01 to get the correct hurdle amount
      if (hurdlePrice && Math.floor(hurdlePrice) === hurdlePrice - 0.01) {
        hurdlePrice -= 0.01
      }

      return {
        isActive: true,
        bannerMessage: promo.bannerMessage,
        mobileBannerMessage: promo.mobileBannerMessage,
        pdpMessage: promo.pdpMessage,
        cartMessage: promo.cartMessage,
        cartMessageBeyondHurdle: promo.cartMessageBeyondHurdle,
        id: promo.id,
        promotionType: promo.promotionType,
        hurdlePrice,
        skuList: promo.clPromos?.[0]?.sku_list,
      }
    })
  }

  async function fetchClPromoData(allGlobalPromos) {
    // Fetch Commerce Layer promo date from promos API
    // to confirm they're active and get any extra information
    const promoIds = allGlobalPromos.map((promo) => promo.promotionIds).flat()

    try {
      const apiBase = process.env.VERCEL
        ? `https://${process.env.VERCEL_URL}/promos`
        : '/promos'

      const response = await $fetch(`${apiBase}/${promoIds.join(',')}`)

      const activeCurrency =
        $commerce.state.cart?.currencyCode ||
        $commerce.state.config?.market?.currency
      // Map commerce layer promos to global promos
      return allGlobalPromos.map((promo) => {
        // Filter to those which match, are active and are in the active currency
        const commerceLayerPromos = Object.values(response).filter(
          (promoData) =>
            promo.promotionIds.includes(promoData.id) &&
            promoData.active &&
            promoData.currency === activeCurrency
        )

        return {
          ...promo,
          clPromos: commerceLayerPromos,
        }
      })
    } catch (error) {
      $sentry?.captureException(error)
      return []
    }
  }

  function filterActivePromos(promos) {
    // Filter to those which have a matching commerce layer promo
    return promos
      .filter((promo) => promo.clPromos.length > 0)
      .filter(
        (promo) =>
          promo.activeFrom &&
          promo.activeFrom < new Date() &&
          promo.activeUntil &&
          promo.activeUntil > new Date()
      )
  }

  function parseMessage(message) {
    // Support **bold**, __italic__ and ~~underline~~
    const bold = /(\*{2})(.*?)(\*{2})/g
    const italic = /(_{2})(.*?)(_{2})/g
    const underline = /(~{2})(.*?)(~{2})/g

    let parsedMessage = message
    parsedMessage = parsedMessage.replace(bold, '<b>$2</b>')
    parsedMessage = parsedMessage.replace(italic, '<i>$2</i>')
    parsedMessage = parsedMessage.replace(underline, '<u>$2</u>')

    return parsedMessage
  }

  function getActivePromosByType(promoTypes) {
    return promoTypes.reduce((previousValue, promoType) => {
      const matchedPromos = promoState.activePromos.filter(
        (activePromo) => activePromo.promotionType === promoType
      )

      matchedPromos.forEach((matchedPromo) => {
        previousValue.push(matchedPromo)
      })

      return previousValue
    }, [])
  }

  // if (process.server) {
  //   // Add hook to store promos into nuxtState
  //   context.beforeNuxtRender(({ nuxtState }) => {
  //     nuxtState.promo = state
  //   })
  // } else {
  //   // Deserialize promos from nuxtState
  //   Object.assign(state, context.nuxtState?.promo || {})
  // }

  return {
    name: 'promo',
    provide: {
      promo: {
        state: promoState,
        getAllGlobalPromos,
        getVerifiedActiveGlobalPromos,
        getActivePromosByType,
        parseMessage,
      }
    },
  }
})
