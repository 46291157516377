import Shipment from './Shipment'
import Address from './Address'
import PaymentMethod from './PaymentMethod'
import PaymentSource from './PaymentSource'

const DIRECT_FIELDS = [
  'id',
  'number',
  'status',
  'languageCode',
  'currencyCode',
  'skusCount',
  'customerEmail',
  'couponCode',
  'giftCardCode',
  'giftCardOrCouponCode',
  'editable',
  'requiresBillingInfo',
  'shippingCountryCodeLock',
  'formattedTotalAmountWithTaxes',
  'subtotalAmountFloat',
  'subtotalAmountCents',
  'shippingAmountFloat',
  'paymentMethodAmountFloat',
  'discountAmountFloat',
  'totalTaxAmountFloat',
  'giftCardAmountFloat',
  'totalAmountWithTaxesFloat',
  'lineItems',
  'lineItemsCount',
  'promotions',
]

export default class Order {
  constructor(data) {
    this._data = data
    DIRECT_FIELDS.forEach((field) => {
      this[field] = data[field]
    })
    this.lineItems = [...data.lineItems].reverse()
  }

  get shipments() {
    return this._data.shipments?.map((shipment) => new Shipment(shipment))
  }

  get discreteLineItems() {
    return this.lineItems?.filter((lineItem) => {
      return !lineItem.isBundle || lineItem.isBaseProduct
    })
  }

  get itemCount() {
    return (
      this.discreteLineItems?.reduce(
        (count, lineItem) => count + lineItem.quantity,
        0
      ) || 0
    )
  }

  get shippingAddress() {
    if (!this._data.shippingAddress) return null
    return new Address(this._data.shippingAddress)
  }

  get billingAddress() {
    if (!this._data.billingAddress) return null
    return new Address(this._data.billingAddress)
  }

  get shippingAddressRequired() {
    // Ideally we'd check the shipments in the order at this point but until an address has been included the shipment objects don't exist
    return this.discreteLineItems.some((lineItem) => lineItem.isPhysical)
  }

  get shipment() {
    if (!this?.shipments) return null

    // If there is more than one shipment in the order
    // then we're dealing with an order containing digital items
    // so just get the physical shipment
    if (this.shipments.length > 1) {
      return this.shipments.find((shipment) => shipment.isPhysical)
    } else {
      return this.shipments[0]
    }
  }

  get shippingMethods() {
    return this.shipment?.availableShippingMethods?.sort(
      (a, b) => a.priceAmountFloat - b.priceAmountFloat
    )
  }

  get shippingMethod() {
    return this.shipment?.shippingMethod
  }

  get availablePaymentMethods() {
    return this._data.availablePaymentMethods.map(
      (method) => new PaymentMethod(method)
    )
  }

  get paymentMethod() {
    return (
      this._data.paymentMethod && new PaymentMethod(this._data.paymentMethod)
    )
  }

  get paymentSource() {
    return (
      this._data.paymentSource && new PaymentSource(this._data.paymentSource)
    )
  }

  get hasFreeShippingPromotion() {
    return !!this.promotions.find(
      (promotion) => promotion.itemType === 'free_shipping_promotions'
    )
  }

  get discountAmountExcludingFreeShipping() {
    return Math.max(
      -this.subtotalAmountFloat,
      this.promotions.reduce((sum, promotion) => {
        if (promotion.itemType === 'free_shipping_promotions') return sum
        return sum + promotion.totalAmountFloat
      }, 0)
    )
  }

  get metadata() {
    return this._data.metadata
  }

  get isReturningCustomer() {
    return this.metadata.returningCustomer
  }

  get sunGodPlusMember() {
    return this.metadata.sungodPlusMember
  }

  get isSubscriber() {
    return this.metadata.subscriber
  }
}
