const now = () => new Date().toISOString()
export default () => `query {
  allGlobalPromos(filter: {
    activeFrom: {
      lte: "${now()}"
    },
    activeUntil: {
      gte: "${now()}"
    },
    enabled: {
      eq: true
    }
  }) {
    id
    promotionType
    promotionIds
    activeFrom
    activeUntil
    bannerMessage
    mobileBannerMessage
    pdpMessage
    cartMessage
    cartMessageBeyondHurdle
  }
}`
