export default {
  checkout(order) {
    if (!window.rdt) return

    window.rdt('track', 'Purchase', {
      currency: order.currency,
      itemCount: order.num_items,
      transactionId: order.id,
      value: order.value
    })
  },

  addToCart(product) {
    if (!window.rdt) return

    window.rdt('track', 'AddToCart', {
      currency: product.currency,
      itemCount: 1,
      value: product.value,
    })
  },
}
