import { getProductPayload } from "./product"

export async function createHash (input) {
  /**
   * This function is used to normalise then hash the input
   * string to a format recognised by Google Ads
   *
   * - Remove leading and trailing whitespace
   * - Convert to lowercase
   * - Hash with hex SHA-256
   */

  if (!input) return null

  // Remove leading and trailing whitespace, convert to lowercase
  const normalisedInput = input.trim().toLowerCase()

  // Hash to SHA-256
  const encoder = new TextEncoder()
  const data = encoder.encode(normalisedInput)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export const getLineItemPayload = (lineItem, customisedProducts) => {
  const customisedProduct = customisedProducts?.[lineItem.id]
  if (!customisedProduct) return null

  return {
    ...getProductPayload(customisedProduct),
    price: lineItem.price,
    discount: lineItem.discount,
    is_digital: lineItem.isDigital,
    is_limited_edition: lineItem.isLimitedEdition,
    is_preorder: lineItem.isPreorder,
    quantity: lineItem.quantity,
  }
}

export const getOrderPayload = async  (order, customisedProducts) => {
  const items = order.discreteLineItems
    ?.map((item) => getLineItemPayload(item, customisedProducts))
    .filter((item) => item !== null)

  const payload = {
    order_id: order.id,
    cart_id: order.id,
    item_count: order.itemCount,
    status: order.status,
    currency: order.currencyCode,
    coupon_code: order.giftCardOrCouponCode,
    total: order.totalAmountWithTaxesFloat,
    revenue: order.totalAmountWithTaxesFloat,
    subtotal: order.subtotalAmountFloat,
    shipping: order.shippingAmountFloat,
    tax: order.taxAmountFloat,
    discount: order.discountAmountFloat,
    items,
    products: items,
    has_preorder_items: order.discreteLineItems?.some(
      (lineItem) => lineItem.isPreorder
    ),
    sha256_email_address:
      await createHash(order.customerEmail)
  }

  const address = order.billingAddress || order.shippingAddress
  if (address?.line1) {
    payload.sha256_phone_number = await createHash(address.phone)

    payload.address = {
      sha256_first_name: await createHash(address.firstName),
      sha256_last_name: await createHash(address.lastName),
      street: address.line1,
      city: address.city,
      region: address.stateCode,
      postal_code: address.zipCode,
      country: address.countryCode
    }
  }

  if (window?.gtag) {
    window.gtag('set', 'user_data', {
      email: order.customerEmail,
      phone: address?.phone,
      street: address?.line1,
      first_name: address?.firstName,
      last_name: address?.lastName,
      city: address?.city,
      region: address?.stateCode,
      postal_code: address?.zipCode,
      country: address?.countryCode
    })
  }

  return payload
}

export const testShouldTrack = (order) => {
  // If this is a cloned order then we don't want to track any events
  if (
    order?.metadata?.clonedFrom || order?.metadata?.cloned_from
  ) return false
  return true
}

export default (ra) => ({
  async view({ order, customisedProducts }) {
    if (!testShouldTrack(order)) return
    await ra.track("Cart Viewed", await getOrderPayload(order, customisedProducts))
  },
})
