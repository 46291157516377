import pinterest from "./pinterest"
import reddit from "./reddit"

const lastEventSKUs = {
  view: null,
}

const isDifferentSKU = (sku, event) => {
  if (!sku) return false // if no sku, don't track

  if (lastEventSKUs[event] !== sku) {
    lastEventSKUs[event] = sku
    return true
  }
  return false
}

export const getProductPayload = (customisedProduct) => ({
  sku: customisedProduct.sku,
  product_id: customisedProduct.sku,
  variant: customisedProduct.sku,
  category: customisedProduct.baseSKU,
  brand: "SunGod",
  legacy_sku: customisedProduct.legacySKU,
  base_sku: customisedProduct.baseSKU,
  name: customisedProduct.name,
  conversion_kit_enabled: customisedProduct.conversionKitEnabled,
  frame_choice_sku: customisedProduct.frameChoice?.sku,
  frame_choice_name: customisedProduct.frameChoice?.plaintextName,
  lens_choice_sku: customisedProduct.lensChoice?.sku,
  lens_choice_name: customisedProduct.lensChoice?.plaintextName,
  frame_type: customisedProduct.frameType,
  is_limited_edition: customisedProduct.isLimitedEdition,
  preorder: customisedProduct.preorderPartSelected,
  in_stock: customisedProduct.isInStock,
  lens_tech: customisedProduct.lensTech,
  price: customisedProduct.price,
  currency: customisedProduct.product.currency,
  url: customisedProduct.url,
  image_url: `https://dynamic.sungod.co/?sku=${customisedProduct.legacySKU}`,
})

export const getFBProductPayload = (customisedProduct) => {
  return {
    currency: customisedProduct.product.currency,
    value: customisedProduct.price,
    content_name: customisedProduct.name,
    content_category: customisedProduct.baseSKU,
    content_ids: [customisedProduct.adFeedSKU],
    content_type: "product",
    delivery_category: "home_delivery",
    contents: [getFBProductContentPayload(customisedProduct)],
  }
}

export const getFBProductContentPayload = (customisedProduct) => {
  return {
    id: customisedProduct.adFeedSKU,
    content_name: customisedProduct.name,
    content_category: customisedProduct.baseSKU,
    item_price: customisedProduct.price,
    quantity: 1,
  }
}

export default (ra, fbq) => ({
  async view(customisedProduct) {
    // Don't track the same SKU twice in a row
    if (!isDifferentSKU(customisedProduct?.sku, "view")) return

    if (customisedProduct.adFeedSKU) {
      fbq.track("ViewContent", getFBProductPayload(customisedProduct))
    }

    await ra.track("Product Viewed", getProductPayload(customisedProduct))
  },

  async customise(customisedProduct) {
    // Don't track the same SKU twice in a row
    if (!isDifferentSKU(customisedProduct?.sku, "customise")) return

    if (customisedProduct.adFeedSKU) {
      fbq.track("CustomizeProduct", getFBProductPayload(customisedProduct))
    }

    await ra.track("Product Customised", getProductPayload(customisedProduct))
  },

  async selectHeroDesign(customisedProduct, heroDesign, heroDesignIndex) {
    // Don't track the same SKU twice in a row
    if (!isDifferentSKU(customisedProduct?.sku, "selectHeroDesign")) return

    await ra.track("Product Hero Design Selected", {
      ...getProductPayload(customisedProduct),
      hero_design_index: heroDesignIndex,
      hero_design_sku: heroDesign.sku,
    })
  },

  async add(customisedProduct, { location, quantity = 1 }) {
    // Don't track the same SKU twice in a row
    if (!isDifferentSKU(customisedProduct?.sku, "add")) return

    if (customisedProduct.adFeedSKU) {
      fbq.track("AddToCart", getFBProductPayload(customisedProduct))
    }

    pinterest.addToCart(getFBProductPayload(customisedProduct))
    reddit.addToCart(getFBProductPayload(customisedProduct))
    
    await ra.track("Product Added", {
      ...getProductPayload(customisedProduct),
      quantity,
      location,
    })
  },

  async remove(customisedProduct) {
    // Don't track the same SKU twice in a row
    if (!isDifferentSKU(customisedProduct?.sku, "remove")) return

    await ra.track("Product Removed", getProductPayload(customisedProduct))
  },

  async upgrade(customisedProduct) {
    // Don't track the same SKU twice in a row
    if (!isDifferentSKU(customisedProduct?.sku, "upgrade")) return

    await ra.track("Product Upgraded", getProductPayload(customisedProduct))
  },

  async lensTechSelected(customisedProduct, lensTech) {
    // Don't track the same SKU and lens tech twice in a row
    if (!isDifferentSKU(`${customisedProduct?.sku}-${lensTech}`, "lenstech"))
      return

    await ra.track("Product Lens Tech Selected", {
      ...getProductPayload(customisedProduct),
      lens_tech: lensTech,
    })
  },

  async frameTypeSelected(customisedProduct, frameType, ckEnabled) {
    // Don't track the same SKU and frame type twice in a row
    if (
      !isDifferentSKU(
        `${customisedProduct?.sku}-${frameType}-${ckEnabled}`,
        "lenstech"
      )
    )
      return

    await ra.track("Product Frame Type Selected", {
      ...getProductPayload(customisedProduct),
      frame_type: frameType,
      conversion_kit_enabled: ckEnabled,
    })
  },

  async show360(customisedProduct) {
    // Don't track the same SKU twice in a row
    if (!isDifferentSKU(customisedProduct?.sku, "show360")) return

    await ra.track("Product 360 Viewed", getProductPayload(customisedProduct))
  },
})
