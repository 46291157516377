import { addressIsTheSame, formatOrder } from '~/assets/js/helpers/orders'
import { setAuthentication } from '~/assets/js/helpers/commerce'
import { camelize } from '@ridi/object-case-converter'

const includes = (items) => `?include=${items.join(',')}`
const orderIncludes = includes([
  'line_items.line_item_options',
  'line_items.stock_line_items',
  'line_items.stock_line_items.stock_item',
  'billing_address',
  'shipping_address',
  'shipments.shipment_line_items.line_item',
  'shipments.available_shipping_methods',
  'shipments.shipping_method',
  'available_payment_methods',
  'payment_method',
  'payment_source',
])

export default ({ client, state }) => ({
  create:
    (attributes) =>
    async (relationships = {}) => {
      await setAuthentication(state)

      const cart = await client.post(`orders${orderIncludes}`, {
        data: { type: 'orders', attributes, relationships },
      })

      return formatOrder(cart)
    },

  read: async (id) => {
    await setAuthentication(state)

    const cart = await client.get(`orders/${id}${orderIncludes}`)

    return formatOrder(cart)
  },

  update: (id) => async (attributes, relationships, retry = true) => {
    await setAuthentication(state)

    async function _update () {
      const cart = await client.patch(`orders/${id}${orderIncludes}`, {
        data: {
          id,
          type: 'orders',
          attributes,
          relationships,
        },
      })

      state.cart = formatOrder(cart)

      return state.cart
    }

    try {
      return await _update()
    } catch (error) {
      const clErrors = error?.response?._data?.errors
      if (clErrors?.some(e => e.detail === 'coupon_code - is expired')) {
        // Remove the coupon from the cart if it's expired and try again
        await client.patch(`orders/${id}`, {
          data: {
            id,
            type: 'orders',
            attributes: {
              gift_card_code: '',
              coupon_code: ''
            }
          }
        })

        if (retry) {
          return await _update()
        }
      }
    }
  },

  async disableAutoRefresh(id) {
    return await this.update(id || state.cart.id)({ autorefresh: false })
  },

  async enableAutoRefresh(id) {
    return await this.update(id || state.cart.id)(
      { autorefresh: true, _refresh: true },
      {}
    )
  },

  async createAddress(address) {
    await setAuthentication(state)
    return await client.post('/addresses', {
      data: {
        type: 'addresses',
        attributes: {
          first_name: address.firstName,
          last_name: address.lastName,
          company: address.company,
          line_1: address.line1,
          line_2: address.line2,
          city: address.city,
          zip_code: address.zipCode,
          state_code: address.stateCode,
          country_code: address.countryCode,
          email: address.email,
          phone: address.phone,
        },
      },
    })
  },

  async removeAddress(id) {
    await setAuthentication(state)

    return await client.delete(`/addresses/${id}`)
  },

  async addEmail(email) {
    await this.update(state.cart.id)({
      customer_email: email,
    })
  },

  async addPrescriptionId(prescriptionId) {
    await this.update(state.cart.id)({
      metadata: {
        prescriptionId,
        ...(state.cart.metadata || {}),
      },
    })
  },

  async addShippingAddressAndEmail(address, orderMetadata) {
    const metadata = {
      ...(state.cart.metadata || {}),
      ...orderMetadata,
    }

    const addressRes = await this.createAddress(address)
    if (!addressRes.dataset.data) throw new Error('Could not create address')

    return this.update(state.cart.id)(
      { customer_email: address.email, metadata },
      {
        shipping_address: {
          data: {
            type: 'addresses',
            id: addressRes?.dataset?.data?.id,
          },
        },
      }
    )
  },

  async addBillingAddress(address) {
    if (addressIsTheSame(state.cart.shippingAddress, address)) {
      return this.update(state.cart.id)({
        _billing_address_same_as_shipping: true,
      })
    }

    const addressRes = await this.createAddress(address)
    if (!addressRes?.dataset?.data) throw new Error('Could not create address')
    return this.update(state.cart.id)(
      {},
      {
        billing_address: {
          data: {
            type: 'addresses',
            id: addressRes?.dataset?.data?.id,
          },
        },
      }
    )
  },

  async updateShipmentShippingMethod(
    shipmentId,
    shippingMethodId,
    metadata = {}
  ) {
    await setAuthentication(state)

    await client.patch(`/shipments/${shipmentId}`, {
      data: {
        type: 'shipments',
        id: shipmentId,
        attributes: {
          metadata,
        },
        relationships: {
          shipping_method: {
            data: {
              type: 'shipping_methods',
              id: shippingMethodId,
            },
          },
        },
      },
    })

    return this.read(state.cart.id)
  },

  async updateOrderPaymentMethod(orderId, paymentMethodId) {
    await setAuthentication(state)

    const order = await client.patch(`orders/${orderId}${orderIncludes}`, {
      data: {
        type: 'orders',
        id: orderId,
        relationships: {
          payment_method: {
            data: {
              type: 'payment_methods',
              id: paymentMethodId,
            },
          },
        },
      },
    })

    return formatOrder(order)
  },

  async createOrderPaymentSource(
    orderId,
    paymentMethod,
    paymentSourceAttributes
  ) {
    await setAuthentication(state)

    const paymentSource = await client.post(
      `/${paymentMethod.paymentSourceType}`,
      {
        data: {
          type: paymentMethod.paymentSourceType,
          attributes: paymentSourceAttributes,
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: orderId,
              },
            },
          },
        },
      }
    )

    // Get data from JSON API normalizer
    // camelCase the keys from for consistency
    return camelize(
      paymentSource.get([
        'id',
        'type',
        'publishable_key',
        'client_secret',
        'options',
      ]),
      { recursive: true }
    )
  },

  async updateOrderPaymentSource(order, paymentSourceAttributes) {
    await setAuthentication(state)

    const { paymentMethod, paymentSource } = order
    await client.patch(
      `/${paymentMethod.paymentSourceType}/${paymentSource.id}`,
      {
        data: {
          type: paymentMethod.paymentSourceType,
          id: paymentSource.id,
          attributes: paymentSourceAttributes,
        },
      }
    )
    return this.read(order.id)
  },

  async place(id, metadata = {}) {
    await setAuthentication(state)

    const order = await client.patch(`orders/${id}${orderIncludes}`, {
      data: {
        type: 'orders',
        id,
        attributes: {
          _place: 1,
          metadata,
        },
      },
    })
    return formatOrder(order)
  },
})
