import { subscribeToQuery } from 'datocms-listen'
import { useClient, useQuery, dedup } from 'villus'
import { batch } from './villus/batch'
// import { cache } from './villus/cache'

import type { ClientPluginContext } from 'villus'
import type { AsyncData } from 'nuxt/app'

export interface Options {
  query: string
  variables?: Record<string, any>
  cachePolicy: 'cache-first' | 'network-only' | 'cache-and-network' | 'cache-only'
}

// export const queryCache = cache({
//   ttl: process.env.DATO_QUERY_CACHE?.length
//     ? parseInt(process.env.DATO_QUERY_CACHE)
//     : 120,
//   browserStorage: 'localStorage'
// })

let hasCreatedClient = false

function hashString (str: string) {
  let hash = 0
  if (str.length == 0) return `${hash}`
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return `${hash}`
}

export default (options: Options): AsyncData<any, any> => {
  const { query, variables = {}, cachePolicy = 'cache-first' } = options

  const { $sentry } = useNuxtApp()

  const {
    public: {
      datoApiToken: token,
      datoEnvironment: environment,
      datoEndpoint: configEndpoint,
    },
  } = useRuntimeConfig()

  // Determine if we are in preview mode
  const isPreviewMode = () => {
    // We use this to ensure we are getting the latest value
    const requestUrl = useRequestURL()
    const previewParam = requestUrl.searchParams?.get('preview')
    return previewParam && ['true', '1', true, 1].includes(previewParam)
  }

  const endpoint = configEndpoint

  function headersPlugin({ opContext, operation }: ClientPluginContext) {
    opContext.headers.Authorization = `Bearer ${token}`

    // Support environments by setting the X-Environment header
    if (environment) {
      opContext.headers['X-Environment'] = environment
    }

    // In preview mode we need to:
    // - add the 'X-Include-Drafts: true' header
    // - change the endpoint
    // - set cache mode to 'network-only'
    if (isPreviewMode()) {
      opContext.headers['X-Include-Drafts'] = 'true'
      opContext.url = configEndpoint.replace('/preview', '') + '/preview'
      operation.cachePolicy = 'network-only'
    }
  }

  if (!hasCreatedClient) {
    useClient({
      url: endpoint,
      use: [headersPlugin, dedup(), batch({
        maxOperationCount: 10,
        maxPseudoComplexity: 300,
        exclude: () => {
          // if (query.includes('exploreLanding')) return true
          return false
        }
      })]
    })

    hasCreatedClient = true
  }

  variables.locale = useDatoLocale().localeForDato

  const operation = useQuery({
    query,
    variables,
    fetchOnMount: false,
    cachePolicy
  })

  const getAsyncDataKey = () => {
    return hashString(JSON.stringify({
      query,
      variables: { ...variables }, // Clone to avoid reference issues
      environment
    }))
  }

  const response = useAsyncData(getAsyncDataKey(), async () => {
    const result = await operation.execute()

    if (result.error) {
      console.error(result.error)
      $sentry.captureException(result.error)
      throw result.error
    }

    return result.data
  })

  // Subscribe to the query and update the data when it changes
  if (isPreviewMode() && process.client) {
    subscribeToQuery({
      query: query,
      variables,
      preview: true,
      token,
      environment,
      onUpdate: (update: { response: { data: any } }) => {
        response.data.value = update.response.data
      },
    })
  }

  // const requestEvent = useRequestEvent()
  // if (requestEvent) { // Undefined clientside
    // const cachePerformance = queryCache.getCachePerformance()
    // requestEvent.context.cachePerformance = cachePerformance
  // }

  return response
}
