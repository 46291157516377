export const productAttributes = [
  'id',
  'code',
  'name',
  'do_not_track',
  'metadata',
  'prices.amount_float',
  'prices.compare_at_amount_float',
  'prices.currency_code',
  'stock_items.quantity',
  'stock_items.metadata',
  'sku_options.name',
  'sku_options.id',
  'sku_options.metadata',
  'sku_options.price_amount_float',
  'sku_options.currency_code',
]
