import { reactive } from 'vue'

export default class Part {
  constructor (data) {
    this._rawData = data

    this.id = data.id
    this.skuPrefix = data.skuPrefix
    this.name = data.name
    this.icon = data.icon
    this.imageLayer = data.imageLayer
    this.followerOf = data.followerOf?.skuPrefix
    this.excludeFromProductDetails = data.excludeFromProductDetails
    this.disableInfiniteSwatchIcon = data.disableInfiniteSwatchIcon
    this.excludeFromAdFeeds = data.excludeFromAdFeeds
    // Trick Vue into thinking this instance already has an observer to prevent it adding a new one
    this.__ob__ = reactive({}).__ob__
  }

  addOptions (getOption) {
    this.options = this._rawData.options.map((option) => {
      return getOption(option.id)
    })

    return this.options
  }

  get isLensPart () {
    return this.options[0].partType === 'lenses'
  }

  get minimumPrice () {
    const prices = this.options.map(
      option => option.price
    ).filter(
      price => price !== null
    )

    if (!prices.length) return 0

    return Math.min(...prices)
  }

  get minimumPrescriptionPrice () {
    const prices = this.options
      .filter(option => option.sku.includes('le_RX'))
      .map(
        option => option.price
      ).filter(
        price => price !== null
      )

      if (!prices.length) return 0

      return Math.min(...prices)
  }

  get minimumCompareAtPrice () {
    const prices = this.options.map(
      option => option.compareAtPrice
    ).filter(
      price => price !== null
    )

    if (!prices.length) return 0

    return Math.min(...prices)
  }

  get stockManagedSKUPrefix () {
    if (!this._stockManagedSKUPrefix) this._stockManagedSKUPrefix = this.options[0].sku.split('_')[0]
    return this._stockManagedSKUPrefix
  }

  get type () {
    return this.options[0]?.partType
  }

  getFilteredOptions (customisedProduct) {
    const pageMode = customisedProduct.product.pageMode

    const filtered = [...this.options].filter(
      (option) => {
        // Filter out parts that can't be sold on their own (part.allowAlone can be unset, hence type checking false)
        if (pageMode === 'parts' && option.allowAlone === false) return false

        // Filter out lenses that don't match the active lens tech
        const isLensPart = option.partType === 'lenses'
        if (!isLensPart) return true
        const lensChoice = customisedProduct.lensChoice
        const isCurrentLensTech = option.lensTech === lensChoice?.lensTech

        // Some lenses have a lens tech defined by a product option, check if the current lens tech is available as an option
        const hasCurrentLensTechAsOption =
          option.productOptions?.some(
            productOption =>
              productOption.lensTech === lensChoice?.lensTech
          )
        const bothArePrescription = lensChoice?.sku?.includes('RX') && option.sku?.includes('RX')
        
        if (
          customisedProduct.allowLensTechFiltering &&
          isLensPart &&
          !isCurrentLensTech &&
          !hasCurrentLensTechAsOption &&
          !bothArePrescription
        ) return false

        return true
      }
    )

    /**
     * As we roll over from non-infinite to infinite parts we need to hide the infinite
     * part until the non-infinite alternative is out of stock
     *
     * This is a temporary solution until we have sold through all non-infinite stock.
     *
     * Non infinite SKUs contain _F or _M
     * Infinite SKUs contain _I
     */

    return filtered.filter(option => {
      // If this is neither inf nor non-inf, return true
      if (!option.isRecycled && !option.isNonRecycled) return true

      // If this is infinite, check if there is an out of stock non-infinite alternative
      if (option.isRecycled) {
        const alternative = this.getNonInfiniteAlternative(option)

        // If there isn't an alternative then return true
        if (!alternative) return true

        // If the alternative is in stock, return false
        return !alternative.isInStock
      }

      // If this is non-infinite, only show if there isn't an infinite alternative
      if (option.isNonRecycled) {
        const alternative = this.getInfiniteAlternative(option)

        // If there isn't an alternative then return true
        if (!alternative) return true

        // If this and the alternative are both in stock then we should show this one
        return option.isInStock && alternative.isInStock
      }

      return true
    })
  }

  getNonInfiniteAlternative (option) {
    return this.options.find(
      alt => (alt.sku === option.sku.replace('_I', '_F')
        || alt.sku === option.sku.replace('_I', '_M'))
        && alt.sku !== option.sku
    )
  }

  getInfiniteAlternative (option) {
    return this.options.find(
      alt => (alt.sku === option.sku.replace('_F', '_I')
        || alt.sku === option.sku.replace('_M', '_I'))
        && alt.sku !== option.sku
    )
  }

  toJSON () {
    return this._rawData
  }

  static fromJSON (data, state) {
    const part = new Part(data)
    part.addOptions(state)
    return part
  }
}
