import { ofetch } from 'ofetch'
import Normalizer from '~/assets/js/normalize'

export const commerceClient = (endpoint, state) => {
  const waitForInit = async (triesRemaining = 100) => {
    if (!state.initialised) {
      if (triesRemaining > 0) {
        await new Promise((resolve) => setTimeout(resolve, 100))
        await waitForInit(triesRemaining - 1)
      } else {
        throw new Error('Commerce plugin not initialised')
      }
    }
  }

  const client = ofetch.create({
    baseURL: `${endpoint}/api`,
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    retry: 3,
    retryDelay: 500,
    retryStatusCodes: [408, 409, 422, 425, 429, 500, 502, 503, 504],
    onRequest: async ({ options }) => {
      await waitForInit()

      options.headers.Authorization = `Bearer ${state.token?.accessToken}`
    },
    onResponse: ({ response }) => {
      if (response._data?.data) {
        response._data = new Normalizer(response._data)
      }
    },
    onResponseError: ({ response }) => {
      // Attempt to build message from error response
      const error = response
      error.responseErrors = error._data?.errors

      error.friendlyMessage =
        error.responseErrors?.map((error) => error?.detail)?.join(', ') ||
        error?.message

      error.message =
        error.responseErrors
          ?.map((error) =>
            error?.code || error?.detail
              ? `${error?.code}: ${error?.detail}`
              : null
          )
          ?.join(', ') || error?.message
    }
  })

  client.get = async function (path, options = {}) {
    return await client(path, options)
  }

  client.patch = async function (path, body, options = {}) {
    return await client(path, {
      ...options,
      method: 'PATCH',
      body,
    })
  }

  client.post = async function (path, body, options = {}) {
    return await client(path, {
      ...options,
      method: 'POST',
      body,
    })
  }

  client.delete = async function (path, options = {}) {
    return await client(path, {
      ...options,
      method: 'DELETE',
    })
  }

  return client
}
