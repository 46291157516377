export default function useDatoLocale() {
  const { $i18n } = useNuxtApp()

  /**
   *
   * Dato locales are different to how we define locales within this app.
   *
   * For example the UK English locale defined within this app is formatted as
   * 'en-gb' but on Dato it is defined as `en_GB`.
   *
   * This method returns a Dato formatted locale based on this apps currently
   * set locale making.
   *
   */
  function getLocaleForDato() {
    if(!$i18n.localeProperties.value.dato) return 'en'

    const splitLocale = $i18n.locale.value.split('-')
    const uppercaseCountry = splitLocale[1].toUpperCase()
    const transformedLocale = splitLocale[0] + '_' + uppercaseCountry

    if (transformedLocale === 'en_GB') {
      return 'en'
    }

    return transformedLocale
  }


  /**
   *
   * Localised records on Dato can have different slugs when localised.
   *
   * This method finds the correct record slug for a localised document
   * based on the current locale.
   *
   */
  function getCorrectSlugForLocale(slugLocaleMapping, fallbackSlug) {
    const matchedLocaleSlugMapping = Object.entries(slugLocaleMapping).find(
      ([_localeSlug, locales]) => {
        return locales.includes($i18n.locale.value)
      }
    )

    if (matchedLocaleSlugMapping.length) {
      return matchedLocaleSlugMapping[0]
    }

    return fallbackSlug
  }

  const localeForDato = getLocaleForDato()

  return { localeForDato, getCorrectSlugForLocale }
}
