export default function mutationMixin(superclass) {
  return class CustomisedProduct extends superclass {
    setChoice(optionSKU, forcePrimary = false) {
      // Get sku prefix
      const [optionSKUPrefix, optionSKUSuffix] = optionSKU.split('_')

      // Don't do anything if this is eg a vf_none SKU
      if (optionSKUSuffix === 'none') return

      // If we're in parts mode then check this is the active part
      if (['parts', 'conversionkit'].includes(this.product?.pageMode))
        this.setActivePart(optionSKUPrefix)

      // If we need to force this choice to primary then check
      // we're not doubling up on a swappable part
      const swappableEntry = this.product.swappableParts.find((swappable) =>
        swappable.includes(optionSKUPrefix)
      )
      const swappableWith = swappableEntry?.find(
        (partSKUPrefix) => partSKUPrefix !== optionSKUPrefix
      )
      let currentlySecondary = optionSKUPrefix in this.state.secondaryChoices

      if (currentlySecondary && forcePrimary) {
        this.swapPrimaryAndSecondaryChoices(swappableWith, optionSKUPrefix)

        // This is no longer a secondary choice
        currentlySecondary = false
      }

      if (currentlySecondary) {
        this.state.secondaryChoices = {
          ...this.state.secondaryChoices,
          [optionSKUPrefix]: optionSKU,
        }
      } else {
        this.state.choices = {
          ...this.state.choices,
          [optionSKUPrefix]: optionSKU,
        }
      }

      this.setFollowerChoices(optionSKU)
      this.checkSwappableParts()
      this.checkPartsMode()
    }

    setChoicesFromSKU(sku) {
      const allOptions = this.product.allOptions

      const options = sku
        .split('-')
        .slice(1)
        .map((optionSKU) => allOptions[optionSKU])
        .filter((option) => option)

      let hasFailedOverLensTech = false

      options.forEach((option) => {
        if (option.partType !== 'lenses') {
          return this.setChoice(option.sku)
        }

        // Get an array of available lenses for this product
        // which match the requested colour
        const availableLenses = this.lensPart.options.filter(
          (lens) => lens.skuWithoutLensTech === option.skuWithoutLensTech
        )

        // Check if the requested lens tech is available and choose it if so
        const skuWithLensTech = option.getSKUWithLensTech(this.lensTech)
        if (availableLenses.find((lens) => lens.sku === skuWithLensTech)) {
          return this.setChoice(skuWithLensTech)
        }

        // If not failover to the first available lens tech and flag that we've failed over
        const firstAvailableLens = availableLenses[0]
        if (firstAvailableLens) {
          hasFailedOverLensTech = true
          return this.setChoice(firstAvailableLens.sku)
        }
      })

      return {
        hasFailedOverLensTech,
      }
    }

    setFollowerChoices(leaderOptionSKU) {
      // Get PartOption instance from leaderOptionSKU
      const [leaderSKUPrefix, leaderSKUSuffix] = leaderOptionSKU.split('_')
      const leaderPart = this.product.parts.find(
        (part) => part.stockManagedSKUPrefix === leaderSKUPrefix
      )

      // Find a matching follower part
      const followerPart = this.product.parts.find(
        (part) => part.followerOf === leaderPart.skuPrefix
      )
      if (!followerPart) return

      let choice = followerPart.options.find((option) =>
        option.sku.endsWith(`_${leaderSKUSuffix}`)
      )

      /**
       * Prefer non-infinite products until they sell out
       */
      if (choice.isRecycled) {
        const nonInfAlternative = followerPart.getNonInfiniteAlternative(choice)
        if (nonInfAlternative && nonInfAlternative.isInStock) {
          choice = nonInfAlternative
        }
      } else if (choice.isNonRecycled && !choice.isInStock) {
        const infAlternative = followerPart.getInfiniteAlternative(choice)
        if (infAlternative && infAlternative.isInStock) {
          choice = infAlternative
        }
      }

      this.setChoice(choice.sku)
    }

    setDefaultChoices(queryParams) {
      this.state.choices = {}
      this.state.secondaryChoices = {}

      // Get default SKU from hero designs or query params
      const initialSKU =
        queryParams?.id || queryParams?.sku || this.heroDesigns?.[0]?.sku
      const initialSecondarySKU = queryParams?.ckid || queryParams?.ckSKU

      // Set choices from queryParams
      if (initialSKU) this.state.choices = this.getChoicesFromSKU(initialSKU)
      if (initialSecondarySKU) {
        this.state.secondaryChoices =
          this.getChoicesFromSKU(initialSecondarySKU)
        this.state.conversionKitEnabled = true
      }

      // Remove any choices that are invalid
      Object.entries(this.choices).forEach(([skuPrefix, choice]) => {
        if (!choice) delete this.state.choices[skuPrefix]
      })
      Object.entries(this.secondaryChoices).forEach(([skuPrefix, choice]) => {
        if (!choice) delete this.state.secondaryChoices[skuPrefix]
      })

      const initChoices = { ...this.state.choices }

      this.product.parts.forEach((part) => {
        // If this part is already defined then skip it
        if (
          part.stockManagedSKUPrefix in this.state.choices ||
          part.stockManagedSKUPrefix in this.state.secondaryChoices
        ) {
          return
        }

        // Identify if this is a swappable part and if so what index it is
        const swappablePartDetails = this.product.swappableParts.find(
          (swappable) => swappable.includes(part.stockManagedSKUPrefix)
        )
        const swappableIndex = swappablePartDetails?.findIndex(
          (skuPrefix) => skuPrefix === part.stockManagedSKUPrefix
        )
        const swappableWith = swappablePartDetails?.find(
          (skuPrefix) => skuPrefix !== part.stockManagedSKUPrefix
        )
        const swappableInitDefined =
          swappableWith && swappableWith in initChoices

        if (
          swappableInitDefined ||
          swappableIndex === 1 ||
          (['parts', 'conversionkit'].includes(this.product?.pageMode) &&
            Object.keys(this.state.choices).length >= 1)
        ) {
          // If:
          //   this is a secondary swappable part
          //   OR we're in parts mode
          //   OR the alternative was defined in initial choices
          // Then store in secondaryChoices
          this.state.secondaryChoices[part.stockManagedSKUPrefix] =
            this.getDefaultChoiceForPartBySKUPrefix(part.stockManagedSKUPrefix)
        } else {
          this.state.choices[part.stockManagedSKUPrefix] =
            this.getDefaultChoiceForPartBySKUPrefix(part.stockManagedSKUPrefix)
        }
      })

      this.ensureChoiceValidity()

      this.state.defaultChoicesSet = true
    }

    getDefaultChoiceForPartBySKUPrefix(skuPrefix) {
      const part = this.product.parts.find(
        (part) =>
          part.stockManagedSKUPrefix === skuPrefix ||
          part.skuPrefix === skuPrefix
      )

      return part.options[0].sku
    }

    getChoicesFromSKU(sku) {
      if (!this.product) return {}

      const optionSKUs = sku.split('-').slice(1)
      const choices = Object.fromEntries(
        optionSKUs
          .map((optionSKU) => {
            const [optionSKUPrefix, optionSKUSuffix] = optionSKU.split('_')
            if (optionSKUSuffix === 'none') return null

            const part = this.product.parts.find(
              (part) =>
                part?.skuPrefix === optionSKUPrefix ||
                part?.stockManagedSKUPrefix === optionSKUPrefix
            )
            if (!part) return null

            let choice = part?.options.find(
              (option) => option.sku === optionSKU
            )

            if (!choice) {
              return [
                part.stockManagedSKUPrefix,
                [part.stockManagedSKUPrefix, optionSKUSuffix].join('_'),
              ]
            }

            /**
             * Prefer non-infinite products until they sell out
             */
            if (choice.isRecycled) {
              const nonInfAlternative = part.getNonInfiniteAlternative(choice)
              if (nonInfAlternative && nonInfAlternative.isInStock) {
                choice = nonInfAlternative
              }
            } else if (choice.isNonRecycled && !choice.isInStock) {
              const infAlternative = part.getInfiniteAlternative(choice)
              if (infAlternative && infAlternative.isInStock) {
                choice = infAlternative
              }
            }

            return [
              part.stockManagedSKUPrefix,
              [part.stockManagedSKUPrefix, choice.skuWithoutPrefix].join('_'),
            ]
          })
          .filter((choice) => choice)
      )

      return choices
    }

    ensureChoiceValidity() {
      // Check that no swappable parts are double-defined
      this.checkSwappableParts()

      // Check that no choices are undefined
      this.checkAllChoicesDefined()

      // Check that nose pieces match frame choice in custom mode
      this.checkNosePieceMatchesFrame()

      // Check only one part is defined in parts mode
      this.checkPartsMode()
    }

    checkNosePieceMatchesFrame() {
      // Only check in custom mode
      if (this.product?.pageMode !== 'custom') return

      // Find the nose piece part, return early if it doesn't exist on this product
      const nosePiecePart = this.product.parts.find(
        (part) => part.type === 'nose_piece'
      )
      if (!nosePiecePart) return

      // Get current choice and expected choice
      const nosePieceChoice =
        this.state.choices[nosePiecePart.stockManagedSKUPrefix] ||
        this.state.secondaryChoices[nosePiecePart.stockManagedSKUPrefix]
      const frameChoiceSuffix = this.frameChoice?.sku?.split('_')?.[1]
      const expectedNosePieceChoice = nosePiecePart.options.find((option) =>
        option.sku.endsWith(`_${frameChoiceSuffix}`)
      )

      // If the choice is already correct then return now
      if (expectedNosePieceChoice?.sku === nosePieceChoice) return

      // Update choice
      if (expectedNosePieceChoice?.sku)
        this.setChoice(expectedNosePieceChoice.sku)
    }

    checkSwappableParts() {
      // Check that no swappable parts are double-defined (eg both nose piece and base frame have choices)
      (this.product.swappableParts || []).forEach(([partSKU1, partSKU2]) => {
        if (partSKU1 in this.state.choices && partSKU2 in this.state.choices) {
          // Both parts are defined in choices
          delete this.state.choices[partSKU2]
        }

        if (
          partSKU1 in this.state.secondaryChoices &&
          partSKU2 in this.state.secondaryChoices
        ) {
          // Both parts are defined in secondaryChoices
          delete this.state.secondaryChoices[partSKU2]
        }

        if (
          partSKU1 in this.state.choices &&
          partSKU1 in this.state.secondaryChoices
        ) {
          // partSKU1 is defined in both choices and secondaryChoices
          delete this.state.secondaryChoices[partSKU1]
        }

        if (
          partSKU2 in this.state.choices &&
          partSKU2 in this.state.secondaryChoices
        ) {
          // partSKU2 is defined in both choices and secondaryChoices
          delete this.state.secondaryChoices[partSKU2]
        }
      })
    }

    checkPartsMode() {
      // Check that we've only got one part selected if we're in parts or CK mode
      if (
        !['parts', 'conversionkit'].includes(this.product?.pageMode) ||
        Object.keys(this.state.choices) === 1
      )
        return

      this.state.choices = Object.fromEntries(
        Object.entries(this.state.choices)
          .filter(([_, option]) => option)
          .slice(0, 1)
      )
    }

    checkAllChoicesDefined() {
      // Check that all choices are defined and if not set a default choice
      const allPrimaryChoicesDefined = Object.values(this.state.choices).every(
        (choice) => choice
      )
      const allSecondaryChoicesDefined = Object.values(
        this.state.secondaryChoices
      ).every((choice) => choice)
      if (allPrimaryChoicesDefined && allSecondaryChoicesDefined) return

      // At least one choice is undefined
      ;[
        ...Object.keys(this.state.choices),
        ...Object.keys(this.state.secondaryChoices),
      ].forEach((skuPrefix) => {
        if (
          this.state.choices[skuPrefix] ||
          this.state.secondaryChoices[skuPrefix]
        )
          return
        this.setChoice(this.getDefaultChoiceForPartBySKUPrefix(skuPrefix))
      })
    }

    setLimitedEditionDefaults() {
      // Handle limited editions with conversion kits
      if (this.product.frameTypeOverride === 'ck') {
        this.state.conversionKitEnabled = true
        this.state.frameTypeOverrideIndex = 0
      }
    }

    setActivePart(optionSKUPrefix) {
      // If this part is already in choices then no change is required
      if (optionSKUPrefix in this.state.choices) return

      const allChoices = {
        ...this.state.choices,
        ...this.state.secondaryChoices,
      }
      this.state.choices = {}
      this.state.secondaryChoices = {}
      Object.entries(allChoices).forEach(([skuPrefix, option]) => {
        if (skuPrefix === optionSKUPrefix)
          this.state.choices[skuPrefix] = option
        else this.state.secondaryChoices[skuPrefix] = option
      })
    }

    swapPrimaryAndSecondaryChoices(primaryChoice, secondaryChoice) {
      if (primaryChoice in this.secondaryChoices) {
        [primaryChoice, secondaryChoice] = [secondaryChoice, primaryChoice]
      }

      // Swap choices between primary and secondary
      [
        this.state.choices[secondaryChoice],
        this.state.secondaryChoices[primaryChoice],
      ] = [
        this.state.secondaryChoices[secondaryChoice],
        this.state.choices[primaryChoice],
      ]

      // Remove old choices
      delete this.state.choices[primaryChoice]
      delete this.state.secondaryChoices[secondaryChoice]

      // Make choices reactive again
      this.state.choices = { ...this.state.choices }
      this.state.secondaryChoices = { ...this.state.secondaryChoices }
    }

    toggleConversionKit(enabled) {
      // Handle Limited Editions differently
      if (this.product.isLimitedEdition) {
        this.state.frameTypeOverrideIndex =
          this.state.frameTypeOverrideIndex === 0 ? 1 : 0
        return
      }

      if (enabled === undefined) {
        this.state.conversionKitEnabled = !this.state.conversionKitEnabled
      } else {
        this.state.conversionKitEnabled = enabled
      }

      // If conversion kit is enabled ensure that it has a design set
      const secondarySKUPrefix = this.secondarySwappableSKUPrefix
      if (
        this.state.conversionKitEnabled &&
        !(secondarySKUPrefix in this.state.secondaryChoices)
      ) {
        this.state.secondaryChoices[secondarySKUPrefix] =
          this.getDefaultChoiceForPartBySKUPrefix(secondarySKUPrefix)
      }
    }

    setFrameType(frameType) {
      if (this.frameType === frameType) return
      this.swapPrimaryAndSecondaryChoices(
        this.primarySwappableSKUPrefix,
        this.secondarySwappableSKUPrefix
      )
      this.ensureChoiceValidity()
    }

    swapFrameType() {
      // Determine the alternative frame type
      const frameTypeDetails = this.frameTypes.find(
        (ft) => !ft.conversionKit && ft.frameType !== this.frameType
      )
      this.setFrameType(frameTypeDetails.frameType)
    }

    trackGetter(getter) {
      if (!this.getterCalls) this.getterCalls = {}
      this.getterCalls[getter] = (this.getterCalls[getter] || 0) + 1
    }

    setSelectedUpsellChoices(key, choice) {
      if (key && choice === null) {
        if (!(key in this.state.selectedUpsellChoices)) return

        delete this.state.selectedUpsellChoices[key]
        return
      }

      if (choice !== null) {
        this.state.selectedUpsellChoices = {
          ...this.state.selectedUpsellChoices,
          [key]: choice,
        }
      }
    }

    setLensTechSelectedState(lensTechSelected) {
      this.state.lensTechSelected = lensTechSelected
    }

    updateProductFromCLResponse (response) {
      this.product.updateFromCLResponse(response)
    }
  }
}
