<template>
  <NuxtLayout>
    <div
      class="ErrorPage global-wrapper"
    >
      <h1 class="ErrorPage__title">
        <span v-if="error.statusCode === 404">404 Not found</span>
        <span v-else>An Error Occured</span>
      </h1>

      <p class="ErrorPage__desc">
        <span v-if="error.statusCode === 404">
          This page may have moved or no longer exists.
        </span>
        <span v-else>The page you requested cannot be loaded.</span>
      </p>

      <VisionButton
        to="/"
        button-text="Return Home"
        background-colour="gradient"
      />

      <p
        v-if="error.data"
        size="body"
        class="ErrorPage__event-id"
      >
        #{{ error.data }}
      </p>
    </div>
  </NuxtLayout>
</template>

<script>
export default {
  name: 'ErrorPage',

  props: {
    error: { type: Object, default: () => {} }
  }
}
</script>

<style lang="postcss">
.ErrorPage {
  @apply my-md;
}

.ErrorPage__title {
  @apply font-bold text-lg mb-2;
}

.ErrorPage__desc {
  @apply text-sm mt-0 mb-6;
}

.ErrorPage__event-id {
  @apply text-xs text-gray-500
    font-mono
    select-all;
}
</style>
