const shippingAddressIncludes = [
  'shipping_address.id',
  'shipping_address.first_name',
  'shipping_address.last_name',
  'shipping_address.company',
  'shipping_address.line_1',
  'shipping_address.line_2',
  'shipping_address.city',
  'shipping_address.zip_code',
  'shipping_address.state_code',
  'shipping_address.country_code',
  'shipping_address.phone',
  'shipping_address.billing_info',
  'shipping_address.notes',
]

const billingAddressIncludes = [
  'billing_address.id',
  'billing_address.first_name',
  'billing_address.last_name',
  'billing_address.company',
  'billing_address.line_1',
  'billing_address.line_2',
  'billing_address.city',
  'billing_address.zip_code',
  'billing_address.state_code',
  'billing_address.country_code',
  'billing_address.phone',
  'billing_address.billing_info',
  'billing_address.notes',
]

const lineItemIncludes = [
  'line_items.item_type',
  'line_items.id',
  'line_items.name',
  'line_items.sku_code',
  'line_items.image_url',
  'line_items.unit_amount_float',
  'line_items.quantity',
  'line_items.total_amount_float',
  'line_items.discount_float',
  'line_items.currency_code',
  'line_items.metadata',
  'line_items.line_item_options.id',
  'line_items.line_item_options.options',
  'line_items.line_item_options.quantity',
  'line_items.line_item_options.unit_amount_float',
  'line_items.line_item_options.total_amount_float',
  'line_items.line_item_options.sku_options',
]

const amountIncludes = [
  'subtotal_amount_float',
  'subtotal_amount_cents',
  'shipping_amount_float',
  'payment_method_amount_float',
  'discount_amount_float',
  'total_tax_amount_float',
  'gift_card_amount_float',
  'total_amount_with_taxes_float',
]

const shipmentIncludes = [
  'shipments.id',
  'shipments.number',
  'shipments.skus_count',
  'shipments.metadata',
  'shipments.shipment_line_items.line_item.item_type',
  'shipments.shipment_line_items.line_item.name',
  'shipments.shipment_line_items.line_item.sku_code',
  'shipments.shipment_line_items.line_item.image_url',
  'shipments.shipment_line_items.quantity',
  'shipments.available_shipping_methods.id',
  'shipments.available_shipping_methods.name',
  'shipments.available_shipping_methods.price_amount_float',
  'shipments.available_shipping_methods.price_amount_for_shipment_float',
  'shipments.available_shipping_methods.free_over_amount_cents',
  'shipments.available_shipping_methods.metadata',
  'shipments.shipping_method.id',
  'shipments.shipping_method.name',
  'shipments.shipping_method.price_amount_float',
  'shipments.shipping_method.price_amount_for_shipment_float',
  'shipments.shipping_method.free_over_amount_cents',
  'shipments.shipping_method.metadata',
]

const paymentMethodIncludes = [
  'available_payment_methods.id',
  'available_payment_methods.name',
  'available_payment_methods.payment_source_type',
  'available_payment_methods.price_amount_float',
  'payment_method.id',
  'payment_method.name',
  'payment_method.payment_source_type',
  'payment_method.price_amount_float',
]

const paymentSourceIncludes = [
  'payment_source.id',
  'payment_source.type',
  'payment_source.publishable_key',
  'payment_source.client_secret',
  'payment_source.options',
]

export const orderAttributes = [
  'id',
  'number',
  'status',
  'language_code',
  'currency_code',
  'skus_count',
  'customer_email',
  'coupon_code',
  'gift_card_code',
  'gift_card_or_coupon_code',
  'editable',
  'requires_billing_info',
  'shipping_country_code_lock',
  'metadata',
  ...amountIncludes,
  ...lineItemIncludes,
  ...billingAddressIncludes,
  ...shippingAddressIncludes,
  ...shipmentIncludes,
  ...paymentSourceIncludes,
  ...paymentMethodIncludes,
]
