export const useAlertStore = defineStore('alerts', {
  state: () => ({
    messages: [],
    messageTimeout: 5 * 1000,
  }),

  getters: {
    sortedMessages: (state) =>
      (state.messages || [])
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice(0, 1),
  },

  actions: {
    ADD_ERROR_MESSAGE({ body, args }) {
      if (typeof body !== 'string')
        body = 'Something went wrong, please try again.'

      this.messages = [
        {
          type: 'error',
          body,
          args,
          timestamp: new Date().valueOf(),
        },
        ...this.messages,
      ]
    },
    ADD_MESSAGE({ body, args }) {
      this.messages = [
        {
          type: 'success',
          body,
          args,
          timestamp: new Date().valueOf(),
        },
        ...this.messages,
      ]
    },
    CLEAN_MESSAGES() {
      this.messages = this.messages.filter(
        (message) =>
          message.timestamp > new Date().valueOf() - this.messageTimeout
      )
    },
  },
})
