export function mapLensTech (lensTech) {
  const lensTechMap = {
    rx: '4ko',
    st: '4ko',
    stp: '4kop',
    iris: '8ko',
    th: '8ko',
    thp: '8kop'
  }
  return lensTechMap[lensTech] || lensTech
}
