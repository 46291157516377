class Normalizer {
  constructor(dataset, property) {
    if (!(this instanceof Normalizer)) {
      const normalizerInstance = new Normalizer(dataset)
      return property ? normalizerInstance.get(property) : normalizerInstance
    }

    if (typeof dataset === 'string') {
      dataset = JSON.parse(dataset)
    }

    if (!dataset || !dataset.data) {
      throw new Error('Invalid json api normalizer input')
    }

    this.dataset = dataset
    this.isCollection = this.isArray(dataset.data)

    this.haystack = this.buildHaystack(
      dataset.included,
      this.isCollection ? dataset.data : [dataset.data]
    )
  }

  buildHaystack(included, additionalItems) {
    const signatures = []
    const haystack = included || []

    this.each(haystack, function (entity) {
      signatures.push(entity.type + '@' + entity.id)
    })

    this.each(additionalItems, function (entity) {
      if (!signatures.includes(entity.type + '@' + entity.id)) {
        haystack.push(entity)
      }
    })

    return haystack
  }

  isArray(collection) {
    return Array.isArray(collection)
  }

  each(collection, callback, context) {
    if (this.isArray(collection)) {
      const iterations = collection.length
      for (let i = 0; i < iterations; i++) {
        callback.call(context, collection[i], i)
      }
    } else {
      for (const key in collection) {
        //eslint-disable-next-line
        collection.hasOwnProperty(key) &&
          callback.call(context, key, collection[key])
      }
    }
  }

  map(collection, callback, context) {
    const temp = []

    this.each(collection, function (item, key) {
      temp.push(callback.call(context, item, key))
    })

    return temp
  }

  deepExtend(out, obj) {
    for (const key in obj) {
      //eslint-disable-next-line
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          typeof out[key] === 'undefined' && (out[key] = {})
          this.deepExtend(out[key], obj[key])
        } else {
          out[key] = obj[key]
        }
      }
    }

    return out
  }

  getEntityProperties(propertyTree, entity, haystack) {
    const data = {}

    this.each(propertyTree, (property, relationPropertyTree) => {
      if (!relationPropertyTree) {
        const relationData =
          entity.relationships &&
          entity.relationships[property] &&
          entity.relationships[property].data

        if (relationData) {
          const relatedEntity = this.getRelatedEntity(relationData, haystack)

          data[property] = relatedEntity
            ? new Normalizer({ data: relatedEntity, included: haystack })
            : undefined
        } else {
          data[property] = this.getEntityValue(property, entity)
        }
      } else {
        data[property] = this.getRelationData(
          property,
          relationPropertyTree,
          entity,
          haystack
        )
      }
    })

    return data
  }

  getEntityValue(property, entity) {
    return ['id', 'type'].includes(property)
      ? entity[property]
      : entity.attributes[property]
  }

  getRelationData(relationName, relationPropertyTree, entity, haystack) {
    const relationData =
      entity.relationships &&
      entity.relationships[relationName] &&
      entity.relationships[relationName].data
    const relatedEntity =
      relationData && this.getRelatedEntity(relationData, haystack)

    if (this.isArray(relatedEntity)) {
      return this.map(relatedEntity, (singleRelatedEntity) => {
        return this.normalizeRelation(
          relationPropertyTree,
          singleRelatedEntity,
          haystack
        )
      })
    } else if (!relatedEntity) {
      return undefined
    } else {
      return this.normalizeRelation(
        relationPropertyTree,
        relatedEntity,
        haystack
      )
    }
  }

  normalizeRelation(propertyTree, relatedEntity, haystack) {
    return this.getViaPropertyTree.call(
      new Normalizer({
        data: relatedEntity,
        included: haystack,
      }),
      propertyTree
    )
  }

  getRelatedEntity(relationData, haystack) {
    const temp = []

    const search = this.isArray(relationData)
      ? this.map(relationData, function (item) {
          return item.type + '@' + item.id
        })
      : [relationData.type + '@' + relationData.id]

    this.each(haystack, function (item) {
      search.includes(item.type + '@' + item.id) && temp.push(item)
    })

    if (temp.length === 0) {
      return undefined
    } else if (temp.length === 1 && !this.isArray(relationData)) {
      return temp[0]
    } else {
      return temp
    }
  }

  arrayToNestedObject(collection) {
    const obj = {}
    let temp = obj
    const collectionLength = collection.length

    for (let i = 0; i < collectionLength; i++) {
      temp = temp[collection[i]] =
        temp[collection[i]] || (i + 1 === collectionLength ? undefined : {})
    }

    return obj
  }

  buildPropertyTree(propertyList) {
    const temp = {}

    this.each(propertyList, (property) => {
      const propertyParts = property.split('.')

      if (propertyParts.length > 1) {
        this.deepExtend(temp, this.arrayToNestedObject(propertyParts))
      } else {
        temp[property] = undefined
      }
    })

    return temp
  }

  getViaPropertyTree(propertyTree) {
    if (this.isCollection) {
      return this.map(
        this.dataset.data,
        (entity) => {
          return this.getEntityProperties(propertyTree, entity, this.haystack)
        },
        this
      )
    } else {
      return this.getEntityProperties(
        propertyTree,
        this.dataset.data,
        this.haystack
      )
    }
  }

  get(property) {
    const result = this.getViaPropertyTree.call(
      this,
      this.buildPropertyTree(this.isArray(property) ? property : [property])
    )

    if (this.isCollection) {
      return this.isArray(property)
        ? result
        : this.map(result, (item) => item[property])
    } else {
      return this.isArray(property) ? result : result[property]
    }
  }
}

export default Normalizer
