export default class PaymentMethod {
  constructor (data) {
    Object.assign(this, data)
  }

  get isStripe () {
    return this.paymentSourceType === 'stripe_payments'
  }

  get isWireTransfer () {
    return this.paymentSourceType === 'wire_transfers'
  }

  get isValid () {
    // Never allow Wire Transfers
    if (this.isWireTransfer) return false
    
    return true
  }
}