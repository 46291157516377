import { getProductPayload } from './product'

export default (ra) => ({
  async toggled({ location, trigger, customisedProduct }) {
    const productPayload = getProductPayload(customisedProduct)

    await ra.track('VTO Visibility Toggled', {
      ...productPayload,
      location,
      trigger,
    })
  },

  async cameraSwitched() {
    await ra.track('VTO Camera Switched')
  },

  async modelSelectModalOpened() {
    await ra.track('VTO Model Select Modal Opened')
  },

  async buyNowClicked() {
    await ra.track('VTO Buy Now Clicked')
  },

  async modelChanged({ baseSKU }) {
    await ra.track('VTO Model Changed', { baseSKU })
  },

  async styleChanged({ baseSKU, sku }) {
    await ra.track('VTO Style Changed', { baseSKU, sku })
  },

  async vtoOpened({ customisedProduct }) {
    const productPayload = getProductPayload(customisedProduct)

    await ra.track('VTO Opened', {
      ...productPayload,
    })
  },

  async vtoLoaded({ timeToLoad }) {
    await ra.track('VTO Loaded', {
      timeToLoad,
    })
  },

  async vtoClosed({ timeSpent }) {
    await ra.track('VTO Closed', {
      timeSpent,
    })
  },
})
