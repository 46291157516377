export default class LineItem {
  constructor(data) {
    this._data = data

    this.formattedTotalAmount = data.formattedTotalAmount
    this.formattedUnitAmount = data.formattedUnitAmount
    this.id = data.id
    this.imageUrl = data.imageUrl
    this.itemType = data.itemType
    this.lineItemOptions = data.lineItemOptions
    this.metadata = data.metadata
    this.name = data.name
    this.quantity = data.quantity
    this.sku = data.sku
    this.totalAmountFloat = data.totalAmountFloat
    this.unitAmountFloat = data.unitAmountFloat
    this.discountAmountFloat = data.discountFloat
    this.currencyCode = data.currencyCode
    this.isBundle = this._isBundle()
    this.bundleCode = this.metadata?.bundleCode
    this.bundleId = this.metadata?.bundleId
    this.isBaseProduct = this._isBaseProduct()
    this._originalPrice = null
    this._price = null
  }

  _isBundle() {
    return !!this.metadata?.bundleCode
  }

  _isBaseProduct() {
    if (!this.isBundle) return false

    const bundleSkus = this.bundleCode.split('-')
    if (!bundleSkus.length) return false

    const baseSku = bundleSkus[0]
    this.baseSku = baseSku
    this.bundleSkus = bundleSkus
    if (!baseSku) return false

    return baseSku === this.sku
  }

  setCompareAtPrices(customisedProduct) {
    this._price = customisedProduct?.price * this.quantity
    this._originalPrice = customisedProduct?.compareAtPrice * this.quantity
  }

  get price() {
    return Math.max(this.originalPrice - this.discount - this.globalDiscount, 0)
  }

  get discount() {
    return (
      -1 *
      (this.discountAmountFloat +
        (this.bundledWith || []).reduce(
          (sum, lineItem) => sum + lineItem.discountAmountFloat,
          0
        ))
    )
  }

  get globalDiscount() {
    if (this._originalPrice === null || this._price === null) return 0
    return this._originalPrice - this._price
  }

  get originalPrice() {
    const bundledWith = this.bundledWith || []

    if (
      this._originalPrice !== null &&
      !bundledWith.some((item) => !!item.lineItemOptions?.length)
    )
      return this._originalPrice

    return (
      this.totalAmountFloat +
      bundledWith.reduce((sum, lineItem) => sum + lineItem.totalAmountFloat, 0)
    )
  }

  get unitPrice() {
    return this.unitAmountFloat
  }

  get isPreorder() {
    return false
  }

  get isLimitedEdition() {
    return this.sku.includes('-')
  }

  get isDigital() {
    return this.sku.startsWith('egiftcard')
  }

  get isPhysical() {
    return !this.isDigital
  }

  get isPrescription() {
    return this.metadata?.prescription
  }

  get additionalProductSKU() {
    return this.metadata?.additionalProductSku
  }

  get associatedLineItemOptions() {
    return [this, ...(this.bundledWith ? this.bundledWith : [])]
      .map((item) => item.lineItemOptions)
      .flat()
      .filter((item) => !!item)
  }
}
