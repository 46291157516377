function normaliseString(str) {
  str = (str || '').toLowerCase()
  str = str.replace(/^,+|,+$/g, '')
  str = str.trim()
  return str
}

export function debounce(func, wait, immediate) {
  let timeout

  return function () {
    const context = this

    const args = arguments

    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}

export function throttleFunction(func, limit) {
  let lastFunc
  let lastRan
  return function () {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export const hashAddress = (address) => {
  if (!address || !Object.keys(address).length) return 0

  let concatenated = normaliseString(address.firstName)
  concatenated += normaliseString(address.lastName)
  concatenated += normaliseString(address.company)
  concatenated += normaliseString(address.line1)
  concatenated += normaliseString(address.line2)
  concatenated += normaliseString(address.city)
  concatenated += normaliseString(address.stateCode)
  concatenated += normaliseString(address.zipCode)
  concatenated += normaliseString(address.countryCode)

  let hash = 0
  let i
  let chr
  if (concatenated.length === 0) return hash
  for (i = 0; i < concatenated.length; i++) {
    chr = concatenated.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }

  address.hash = hash
  return address
}
