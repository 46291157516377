import { hashAddress } from '~/assets/js/helpers'
import { useAlertStore } from './alerts'

// eslint-disable  no-unused-vars
function getUniqueAddresses(addresses, phoneNumber) {
  addresses = addresses
    .map((address) => {
      try {
        if (typeof address === 'string') address = JSON.parse(address)
        if (typeof address !== 'object' || address === null) return false

        address.firstName =
          (address.name || '').split(' ')[0] || address.firstName
        address.lastName =
          (address.name || '').split(' ').slice(1).join(' ') || address.lastName
        address.phone = phoneNumber || address.phone
        return address
      } catch (error) {
        return error
      }
    })
    .filter((address) => address)
    .map(hashAddress)

  const uniqueAddresses = []
  addresses.forEach((address) => {
    if (uniqueAddresses.find((a2) => a2.hash === address.hash)) return
    uniqueAddresses.push(address)
  })
  return uniqueAddresses
}
// eslint-enable  no-unused-vars

export const useCheckoutStore = defineStore('checkout', {
  state: () => ({
    addresses: [],
    phoneNumbers: {},
    stepState: {},
    order: {},
    items: [],
    coupons: [],
    signupChoice: null,
    market: null,
    shippingMethodBackup: null,
    chosenShippingMethod: {},
  }),
  getters: {
    phoneNumber: (state) => (email) => state.phoneNumbers[email] || null,
    hasVerifiedEmail: (state) => (email) => email in state.phoneNumbers,
    addressHash: () => (address) => hashAddress(address).hash,
    shippingAddress: (state) => state.order?.shippingAddress,
    billingAddress: (state) => state.order?.billingAddress,
    orderId: (state) => state.order?.id,
    lineItems: (state) => state.order?.lineItems,
    discreteLineItems: (state) => state.order?.discreteLineItems,
    itemCount: (state) => state.order?.itemCount || 0,
    coupon: (state) => state.order.coupon_code,
    shippingMethods: (state) => state.order?.shippingMethods,
    shipment: (state) => state.order?.shipment,
    shippingMethod: (state) => state.order?.shippingMethod,
    paymentMethods: (state) => state.order?.availablePaymentMethods,
    paymentMethod: (state) => state.order?.paymentMethod || {},
    isPrescription: (state) => !!(state.order?.lineItems || []).some(
      (lineItem) => lineItem?.metadata?.prescription
    ),
    isClonedOrder: (state) => state.order?.metadata?.clonedFrom
      || state.order?.metadata?.cloned_from
  },

  actions: {
    SET_ADDRESSES({ addresses, phoneNumber }) {
      const { $sentry } = useNuxtApp()

      try {
        this.addresses = getUniqueAddresses.bind(this)(addresses, phoneNumber)
      } catch (error) {
        $sentry.captureException(error)
        console.log(error)
      }
    },

    ADD_ADDRESS(address) {
      if (!address || !address.line1 || !address.firstName) return

      const { $sentry } = useNuxtApp()

      // Don't add gift vouchers or express checkout dummy addresses to list of addresses
      if (
        (address.company || '').toLowerCase().includes('gift vouchers') ||
        (address.lastName || '').toLowerCase().includes('gift vouchers') ||
        (address.firstName || '').toLowerCase().includes('express')
      )
        return

      try {
        this.addresses = getUniqueAddresses.bind(this)(
          [address, ...(this.addresses || [])],
          address.phone
        )
      } catch (error) {
        console.log(error)
        $sentry.captureException(error)
      }
    },

    SET_PHONE_NUMBER({ email, phoneNumber }) {
      this.phoneNumbers = {
        ...this.phoneNumbers,
        [email]: phoneNumber,
      }
    },

    CLEAR_SESSION() {
      const market = this.market
      this.$reset()
      this.market = market
    },

    SET_ORDER(order) {
      this.order = order
    },

    RESET() {
      this.$reset()
    },

    SET_SIGNUP_CHOICE(choice) {
      this.signupChoice = choice
    },

    SET_MARKET(market) {
      this.market = market
    },

    SET_SHIPPING_METHOD_BACKUP(shippingMethodBackup) {
      this.shippingMethodBackup = shippingMethodBackup
    },

    SET_CHOSEN_SHIPPING_METHOD(chosenMethodId) {
      this.chosenShippingMethod =
        this.order?.shippingMethods?.find(
          (method) => method.id === chosenMethodId
        ) || {}
    },

    async CHECK_EMAIL({ email, sendSMS }) {
      return await $fetch(
        '/api/v1/checkout/validate-email/',
        {
          params: { email, send_sms: sendSMS ? 'true' : 'false' },
        }
      )
    },

    async VERIFY_PIN({ email, pin, last4 }) {
      const data = await $fetch('/api/v1/checkout/verify-pin/', {
        method: 'POST',
        body: { email, pin, last4 }
      })
      if (data.valid) {
        const addresses = data.addresses.map((address) => {
          if (typeof address === 'string') address = JSON.parse(address)

          return {
            name: address.name,
            company: address.company,
            line1: address.address1,
            line2: address.address2,
            city: address.city,
            stateCode: address.state,
            zipCode: address.postCode,
            countryCode: address.country,
            phone: address.telephone,
          }
        })

        this.SET_ADDRESSES({ addresses, phoneNumber: data.phone_number })
        this.SET_PHONE_NUMBER({ email, phoneNumber: data.phone_number })
      }
      return data
    },

    async SAVE_SHIPPING({ address, orderMetadata = {} }) {
      const alertStore = useAlertStore()
      const { $commerce, $sentry } = useNuxtApp()

      try {
        const order = await $commerce.order.addShippingAddressAndEmail(
          address,
          orderMetadata
        )
        this.ADD_ADDRESS(address)
        this.SET_ORDER(order)

        return order
      } catch (error) {
        console.error(error)
        $sentry.captureException(error)
        alertStore.ADD_ERROR_MESSAGE(
          { body: 'Unable to save shipping address. Please try again.' },
          { root: true }
        )
        return false
      }
    },

    async SAVE_SHIPPING_METHOD({
      shipmentId,
      methodId,
      signUp,
      metadata = {},
    }) {
      const alertStore = useAlertStore()
      const { $sentry, $commerce } = useNuxtApp()

      try {
        const order = await $commerce.order.updateShipmentShippingMethod(
          shipmentId,
          methodId,
          metadata
        )

        this.SET_ORDER(order)
        this.SET_SHIPPING_METHOD_BACKUP({
          shipmentId,
          methodId,
          signUp,
          metadata,
        })
        this.SET_CHOSEN_SHIPPING_METHOD(methodId)
        this.SET_SIGNUP_CHOICE(signUp)
        return order
      } catch (error) {
        console.error(error)
        $sentry.captureException(error)
        alertStore.ADD_ERROR_MESSAGE(
          { body: 'Unable to save shipping method. Please try again.' },
          { root: true }
        )
        return false
      }
    },

    async SAVE_DIGITAL_SHIPPING_METHOD() {
      const alertStore = useAlertStore()
      const { $sentry, $commerce } = useNuxtApp()

      try {
        const digitalShipment = this.order.shipments.find((s) =>
          s.availableShippingMethods.some((m) => m.name.match(/digital/i))
        )

        // If there isn't a digital shipment then just return now
        if (!digitalShipment) return true

        // Get shipment ID and method ID
        const shipmentId = digitalShipment.id
        const methodId = digitalShipment.availableShippingMethods[0].id

        const order = await $commerce.order.updateShipmentShippingMethod(
          shipmentId,
          methodId
        )

        this.SET_ORDER(order)

        return order
      } catch (error) {
        console.error('SAVE_SHIPPING_METHOD', error)
        $sentry.captureException(error)
        alertStore.ADD_ERROR_MESSAGE(
          { body: 'Unable to save shipping method. Please try again.' },
          { root: true }
        )
        return false
      }
    },

    async SAVE_BILLING(address) {
      const alertStore = useAlertStore()
      const { $sentry, $commerce } = useNuxtApp()

      try {
        const order = await $commerce.order.addBillingAddress(address)

        this.SET_ORDER(order)
        this.ADD_ADDRESS(address)

        return order
      } catch (error) {
        console.error(error)
        $sentry.captureException(error)
        alertStore.ADD_ERROR_MESSAGE(
          { body: 'Unable to save billing address. Please try again.' },
          { root: true }
        )
        return false
      }
    },

    async SETUP_PAYMENT() {
      const { paymentMethod, id, availablePaymentMethods } = this.order
      const { $commerce } = useNuxtApp()
      const availablePaymentMethod = availablePaymentMethods[0]

      let updatedOrder
      if (!paymentMethod) {
        updatedOrder = await $commerce.order.updateOrderPaymentMethod(
          id,
          availablePaymentMethod?.id
        )
      }

      const hasPaymentMethod =
        paymentMethod?.id || updatedOrder?.paymentMethod?.id
      if (!hasPaymentMethod || !availablePaymentMethod) {
        throw new Error('No payment method available')
      }

      if (this.order.paymentSource?.id) return this.order.paymentSource

      return await $commerce.order.createOrderPaymentSource(
        id,
        availablePaymentMethod
      )
    },

    async MAKE_PAYMENT(paymentMethod) {
      const { $commerce } = useNuxtApp()

      const order = await $commerce.order.updateOrderPaymentSource(
        this.order,
        {
          options: {
            payment_method: paymentMethod.id,
            customer: paymentMethod.customer,
          },
        }
      )

      if (order) this.SET_ORDER(order)
    },

    async SAVE_ORDER({ metadata }) {
      const alertStore = useAlertStore()
      const { $sentry, $commerce } = useNuxtApp()

      try {
        const order = await $commerce.order.place(this.order.id, metadata)

        return order
      } catch (error) {
        $sentry.withScope((scope) => {
          scope.setExtra(
            'response_data',
            JSON.stringify(error?.response?.data || {}, null, 1)
          )
          $sentry.captureException(error)
        })

        console.error(error)

        alertStore.ADD_ERROR_MESSAGE(
          {
            body: error.friendlyMessage
              ? `Unable to submit order: ${error.friendlyMessage}`
              : 'Unable to submit order. Please try again.',
          },
          { root: true }
        )
        return false
      }
    },
  },
})
