import { orderAttributes } from '~/assets/js/constants/orderAttributes'
import Order from '~/models/Order'
import LineItem from '~/models/LineItem.js'
import { getImg } from '~/assets/js/helpers/lineItems'
import { camelize } from '@ridi/object-case-converter'

export const formatOrder = (cart) => {
  if (!cart) return

  const unformatted = camelize(cart.get(orderAttributes), { recursive: true })

  const lineItems = unformatted.lineItems
    ? unformatted.lineItems.reduce((acc, i) => {
        if (i.itemType !== 'skus') return acc
        const { skuCode, ...rest } = i
        return [...acc, { ...rest, sku: skuCode }]
      }, [])
    : []

  const lineItemsCount = lineItems.reduce(
    (acc, lineItem) => acc + lineItem.quantity,
    0
  )

  const promotions =
    unformatted.lineItems?.filter((lineItem) =>
      lineItem.itemType?.includes('promotion')
    ) || []

  // If we've got any promotions from Pulse then we can
  // reassign the discounts between line items for nicer calculation.
  // Note that we don't support reassigning discounts if there are multiple promotions
  const pulsePromotionResult = promotions.find(promo => promo.itemType === 'external_promotions')
  if (pulsePromotionResult && promotions.length === 1) {
    let discountToAssign = -1 * pulsePromotionResult.totalAmountFloat
    const targetLineItemIds = pulsePromotionResult.metadata.appliesTo

    // Start by resetting discounts on all line items up to the total discount
    let discountReset = 0
    lineItems.forEach((lineItem) => {
      const initialDiscount = -1 * lineItem.discountFloat
      const discountLeftToAssign = discountToAssign - discountReset
      const newDiscount = initialDiscount
        - Math.min(discountLeftToAssign, initialDiscount)
      discountReset += initialDiscount - newDiscount
      lineItem.discountFloat = Math.round(-1 * newDiscount * 100) / 100
    })

    // If we have any discount left to assign, we can assign it to the first line item with a price
    if (discountToAssign > discountReset) {
      const firstLineItemWithPrice = lineItems.find(
        (lineItem) => lineItem.totalAmountFloat > 0
      )
      if (firstLineItemWithPrice) {
        firstLineItemWithPrice.discountFloat -= discountToAssign - discountReset
      }
    }

    // If there is still any discount left to assign, we should log an error
    if (discountToAssign > discountReset) {
      console.error('Could not assign all discounts to line items')
    }

    // Now assign the discount to the line items that are targeted by the promotion
    lineItems.forEach((lineItem) => {
      if (!targetLineItemIds.includes(lineItem.id)) return

      const newDiscount = Math.min(lineItem.totalAmountFloat, discountToAssign)
      lineItem.discountFloat -= Math.round(newDiscount * 100) / 100
      discountToAssign -= newDiscount
    })
  }

  const lineItemInstances = lineItems.map((item) => new LineItem(item))

  lineItemInstances.forEach((lineItem) => {
    // Add references to bundled line items
    if (lineItem.bundleId) {
      // Attempt to link by bundleId first
      lineItem.bundledWith = lineItemInstances.filter(
        (otherLineItem) =>
          otherLineItem.id !== lineItem.id &&
          otherLineItem.bundleId === lineItem.bundleId
      )
    } else if (lineItem.bundleCode) {
      // Failover to linking by bundleCode - can be removed in the future when all draft orders have been placed
      lineItem.bundledWith = lineItemInstances.filter(
        (otherLineItem) =>
          otherLineItem.id !== lineItem.id &&
          otherLineItem.bundleCode === lineItem.bundleCode
      )
    }
  })

  return new Order({
    ...unformatted,
    lineItems: lineItemInstances,
    lineItemsCount,
    promotions,
  })
}

const steps = {
  one: null,
  two: {
    shipping: { complete: true },
    shipping_method: { allow: true },
  },
  three: {
    shipping: { complete: true },
    shipping_method: { allow: true, complete: true },
    billing: { allow: true },
  },
  four: {
    shipping: { complete: true },
    shipping_method: { allow: true, complete: true },
    billing: { allow: true, complete: true },
    payment: { allow: true },
  },
}

export const calculateStepStates = (order) => {
  if (!order || !order.customerEmail || !order.shippingAddress.line1)
    return steps.one

  if (!order?.shipments[0]?.shippingMethod) return steps.two

  if (!order?.billingAddress?.line1) return steps.three

  return steps.four
}

export const addressIsTheSame = (originalAddress, address) => {
  if (!originalAddress || !address) return false
  const keysToCheck = [
    'city',
    'company',
    'countryCode',
    'email',
    'firstName',
    'lastName',
    'line1',
    'line2',
    'phone',
    'stateCode',
    'zipCode',
  ]
  return keysToCheck.every((key) => originalAddress[key] === address[key])
}

export const transformLineItems = (lineItems, products, configSha) => {
  const baseSkus = lineItems?.filter((i) => i.isBaseProduct)?.map((i) => i.sku)
  const formatted = lineItems
    ?.map((lineItem) => {
      if (lineItem.isBundle)
        return transformBundleLineItem(baseSkus, lineItem, products, configSha)
      return transformLineItem(lineItem, products, configSha)
    })
    .filter(
      (lineItem) =>
        !lineItem.isBundle || (lineItem.isBundle && lineItem.isBaseProduct)
    )

  return groupDuplicateLineItems(formatted)
}

const transformBundleLineItem = (baseSkus, lineItem, products, configSha) => {
  const baseProducts = baseSkus?.map((sku) =>
    products.find((p) => p?.sku === sku)
  )
  const { sku, isBaseProduct, isBundle, bundleSkus, bundleCode } = lineItem
  const baseProduct =
    isBundle && isBaseProduct && baseProducts.find((p) => p.sku === sku)
  const baseProductParts = baseProduct?.parts?.map((part) => ({
    ...part,
    option: part.options?.find((option) => bundleSkus?.includes(option.sku)),
  }))
  const options = baseProductParts?.map((p) => p.option).flat(Infinity)

  return {
    ...lineItem,
    title: baseProduct?.name,
    subTitle: baseProduct?.subTitle,
    getImage: (options) => getImg(bundleCode, options, configSha),
    parts: baseProductParts,
    isPreorder: bundleCode?.includes('po_preorder'),
    totalPrice: options?.reduce(
      (total, option) => total + option?.amountFloat,
      0
    ),
  }
}

const transformLineItem = (lineItem, products, configSha) => {
  const productInfo = products.find((p) => p?.sku === lineItem?.sku)

  return {
    ...lineItem,
    title: productInfo?.name,
    subTitle: productInfo?.subTitle,
    getImage: (options) => getImg(lineItem.sku, options, configSha),
    // @TODO pre-order with non-bundle item @rob.watkiss how will this be done?.
    // isPreorder: bundleCode?.includes('po_preorder'),
    totalPrice: lineItem.totalAmountFloat,
  }
}

const groupDuplicateLineItems = (lineItems) => {
  return lineItems?.reduce((groupedItems, lineItem) => {
    const attr = lineItem.isBundle ? 'bundleCode' : 'sku'

    const originalItemIndex = groupedItems.findIndex(
      (i) => i[attr] === lineItem[attr]
    )
    const itemAlreadyInGroupedItems = originalItemIndex !== -1

    if (itemAlreadyInGroupedItems) {
      const item = groupedItems[originalItemIndex]
      item.quantity += 1
      item.totalPrice = item.totalPrice * item.quantity

      groupedItems[originalItemIndex] = item
    } else {
      groupedItems.push(lineItem)
    }

    return groupedItems
  }, [])
}
