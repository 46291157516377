import allLayerTemplates from '~/assets/360/all.mjs'

const sortedLayerTemplateKeys = [...Object.keys(allLayerTemplates)].sort(
  (a, b) => b.length - a.length
)

const noop = () => { }

function buildStacked360URL(params) {
  const sku = params.sku
  if (!sku) return null

  // Check if we have 360 renders for this SKU
  const matchingLayerTemplateKey = sortedLayerTemplateKeys.find((key) => {
    if (key.includes('-')) return sku.startsWith(key)
    return sku.startsWith(`${key}-`)
  })
  if (!matchingLayerTemplateKey) return null // If not then return null and failover to legacy dynamic image generator
  const layerTemplate = allLayerTemplates[matchingLayerTemplateKey]

  // Determine frame number
  let frameNumber = 1
  if (params.frame) frameNumber = params.frame
  else if (params.image_number)
    frameNumber =
      {
        1: 34,
        2: 14,
        3: 58,
        4: 49,
        5: 1,
        6: 46,
      }[params.image_number] || frameNumber

  // Check if we need to remove clouds background
  const includeBackground = params.bg === 'clouds'

  // Check if we can use staticPathTemplate - has to be frames 1, 14 or 34
  const canUseStaticPath = [1, 14, 34].includes(frameNumber)

  // Build an array of images
  const choices = (layerTemplate.resolveOptionsFromSKU || noop)(sku)
  let layers = layerTemplate.layers
    .filter(
      (layer) =>
        !layer.isReflection && (includeBackground || !layer.isBackground)
    )
    .map((layer) => {
      if (layer.hasOptions === false)
        return (
          (canUseStaticPath && layer.staticPathTemplate) || layer.pathTemplate
        )
      const choiceDetails = layer.options?.[choices?.[layer.id]]
      return (
        (canUseStaticPath && choiceDetails?.staticPathTemplate) ||
        choiceDetails?.pathTemplate
      )
    })
  layers = layers
    .map((layer) =>
      layer
        ?.split('gs://sg360/')?.[1]
        ?.replace('', '')
        ?.replace(/{nnn}/g, frameNumber.toString().padStart(3, 0))
    )
    .filter((layer) => layer)

  // If we've got not layers the failover and hope the legacy dynamic generator can help
  if (!layers || !layers.length) return null

  // Determine source image crop based on scale
  // At scale=1 we need crop:2800
  // At scale=1.4 we need crop:2000
  // At scale=1.7 we need crop:1600
  const scale = parseFloat(params.scale || '1')
  const xCrop =
    parseFloat(params.crop_factor) || 950 * scale ** 2 - 4285 * scale + 6133
  const yCrop = xCrop

  // Build full imgProxySettings
  const stackedURL = `https://sg360-stack-cf.sungod.co/?paths=${encodeURIComponent(
    layers.join(',')
  )}`

  function bytesToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes)
    return btoa(binString)
  }

  const encodedStackedURL = bytesToBase64(
    new TextEncoder().encode(stackedURL)
  ).toString('base64')
    .replace('+', '-')
    .replace('/', '_')
    .replace(/=+$/, '')
  const imgProxySettings = [
    'insecure',
    `crop:${xCrop.toFixed()}:${yCrop.toFixed()}`,
  ]

  // Resize
  if (params.w || params.width || params.h || params.height) {
    let width = parseInt(params.w || params.width || '')
    let height = parseInt(params.h || params.height || '')
    if (width && !height) height = width / 2
    else if (height && !width) width = height * 2
    imgProxySettings.push(
      `resize:auto:${width.toFixed()}:${height.toFixed()}`
    )
    imgProxySettings.push('extend:1:ce')
  }

  // Add background
  const bgColour = params.background || params.bg
  if (bgColour && !['clouds', 'transparent'].includes(bgColour)) {
    imgProxySettings.push(`bg:${bgColour}`)
  }

  return new URL(
    [...imgProxySettings, encodedStackedURL].join('/'),
    'https://360.sungod-assets.com/'
  )
}

export function buildProductImageURL (params) {
  const stacked360URL = buildStacked360URL(params)

  if (stacked360URL) return stacked360URL.toString()

  // Fallover to dimg API which can in turn pull static images from DatoCMS
  // or the legacy dynamic image generator.
  // We can't do this here as we need to be able to return synchronously.
  // eslint-disable-next-line vue/max-len
  return  `/dimg?${new URLSearchParams(params)}`
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      dimg: {
        url: buildProductImageURL
      }
    }
  }
})
