import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry: config } } = useRuntimeConfig()

  if (!config.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.dsn,
    environment: config.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      })
    ],

    tracesSampleRate: Number(config.clientTraceRate ?? 0.2),

    tracePropagationTargets: ['localhost', /^\//], // This is the default Sentry value

    beforeSend: (event, hint) => {
      console.error(hint.originalException || hint.syntheticException, event)
      return event
    }
  })

  return {
    provide: {
      sentry: {
        captureException: (exception) => {
          return Sentry.captureException(exception?.value || exception)
        },
        withScope: Sentry.withScope
      }
    }
  }
})