import { useAlertStore } from './alerts'

export const useCouponStore = defineStore('coupon', {
  state: () => ({
    couponDetails: {}
  }),

  actions: {
    STORE_COUPON_DETAILS ({ coupon, response }) {
      this.couponDetails = {
        ...this.couponDetails,
        [coupon]: response
      }
    },

    async APPLY_COUPON ({ coupon, silent = false }) {
      const { $commerce, $sentry } = useNuxtApp()
      const alertStore = useAlertStore()

      try {
        const data = await $commerce.coupon.add(coupon, silent)

        const lineItems = $commerce.state.cart.discreteLineItems
        const winnersInCart = lineItems.some(item => item.sku === 'tokas-le_mclarenwinners')
        if (winnersInCart && data.mode === 'coupon') {
          await $commerce.coupon.remove(true)
          alertStore.ADD_ERROR_MESSAGE({
            body: 'Discount codes are not valid with the McLaren Winners\' Collection.'
          })
          return { status: 'ERROR' }
        }

        await this.GET_COUPON_DETAILS(coupon)
        
        return data
      } catch (error) {
        alertStore.ADD_ERROR_MESSAGE({ body: 'Unable to apply coupon. Please try again.' })
        if (!error.message.includes('not valid')) $sentry.captureException(error)
        return { status: 'ERROR' }
      }
    },
  
    async REMOVE_COUPON () {
      const { $commerce, $sentry } = useNuxtApp()
      const alertStore = useAlertStore()

      try {
        const data = await $commerce.coupon.remove()
        return data
      } catch (error) {
        alertStore.ADD_ERROR_MESSAGE({ body: 'Unable to remove coupon. Please try again.' })
        $sentry.captureException(error)
        return false
      }
    },
  
    async GET_COUPON_DETAILS (coupon) {
      const { $sentry } = useNuxtApp()

      // If we already have the coupon details, don't fetch them again
      if (this.couponDetails[coupon]) return
      
      try {
        const response = await $fetch(`/coupons/${coupon}`)
        if (response) this.STORE_COUPON_DETAILS({ coupon, response })
      } catch (error) {
        $sentry.captureException(error)
      }
    }
  },
})
