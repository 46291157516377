import { setAuthentication } from '~/assets/js/helpers/commerce'

export default ({ client, state }) => ({
  create: (lineItemId) => async (attributes) => {
    await setAuthentication(state)

    const { id, quantity, _name: name, ...rest } = attributes

    const lineItemOption = await client.post('line_item_options', {
      data: {
        type: 'line_item_options',
        attributes: {
          quantity,
          options: {
            ...rest,
            optionId: id,
            name,
          },
        },
        relationships: {
          line_item: {
            data: {
              type: 'line_items',
              id: lineItemId,
            },
          },
          sku_option: {
            data: {
              type: 'sku_options',
              id,
            },
          },
        },
      },
    })

    return lineItemOption
  },
  update: (id) => async (attributes) => {
    await setAuthentication(state)
    return await client.patch(`line_item_options/${id}`, {
      data: {
        id,
        type: 'line_item_options',
        attributes: {
          ...attributes,
        },
      },
    })
  },
})
